import { Component } from "react";
import UserTable from "./UserTable";
import UserTableWait from "./UserTableWait";
import Tabs, { TabPane } from 'rc-tabs';
import Alerts from "./alerts";
import axios from "axios";
import config from '../config.json'

export default class CallIn extends Component{

    constructor(){
        super()
        this.state = {
            errors: [],
            friendsDataWait:[],
            friendsDataAllow:[]
        }
    }

    groupBy = (array, key, status) => {
        return array.reduce((result, currentValue) => {
            if( currentValue.status === status ){
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            }
            return result
        },{})
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/friend/${sessionStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' ){
                    this.setState({
                        errors:['info', 'Nie masz jeszcze żadnych zaproszeń.']
                    })
                }else{
                    const friData = res.data
                    this.setState({
                        friendsDataWait: this.groupBy( friData, 'date', "0"),
                        friendsDataAllow: this.groupBy( friData, 'date', "1")
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
            })
    }

    render(){
        if( this.state.errors.length > 0 ){
            return(
                <div className="account_box p-5">
                    <h2 className="text-center">Lista otrzymanych zaproszeń</h2>
                    <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                </div>
            )
        }
        const {friendsDataWait, friendsDataAllow} = this.state
        return(
            <div className="account_box p-5">
                <h2 className="text-center">Lista otrzymanych zaproszeń</h2>
                <Tabs>
                    <TabPane tab="Oczekujące" key="1">
                        {Object.keys(friendsDataWait).map((data,index) => <UserTableWait key={index} label={data} data={friendsDataWait[data]}/>)}
                    </TabPane>
                    <TabPane tab="Zaakceptowane" key="2">
                        {Object.keys(friendsDataAllow).map((data,index) => <UserTable key={index} label={data} data={friendsDataAllow[data]}/>)}
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}