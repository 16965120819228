import { Component } from "react";
import axios from "axios";
import Avatar from "./Avatar";
import Alerts from "./alerts";
import config from '../config.json';

export default class Visit extends Component{
    constructor(props){
        super(props)
        this.state = {
            data:[],
            errors:[]
        }
    }
    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/visits/${sessionStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' ){
                    this.setState({
                        errors:['info', 'Nie rozpocząłeś jeszcze żadnej konwersacji.']
                    })
                }else{
                    this.setState({
                        data: res.data
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === undefined ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                this.setState({
                    errors:['warning']
                })
            })
    }
    goToVisitor = e => {
        e.preventDefault();
        localStorage.setItem('showUser', e.target.dataset.visitor);
        localStorage.setItem('currentView','user-profile');
        window.location.reload(true);
    }
    render(){
        if( this.state.errors.length > 0 ){
            return(
                <div className="account_box p-5">
                    <h2 className="text-center">Kto mnie odwiedził</h2>
                    <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                </div>
            )
        }
        return(
            <div className="account_box p-5">
                <h2 className="text-center">Kto mnie odwiedził</h2>
                <table className="users_list">
                    <thead>
                        <tr>
                            <th colSpan="2">
                                {/* {this.props.label} */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map( (data, index) => 
                            <tr key={index}>
                                <td className="user">
                                    <a href="#" onClick={this.goToVisitor} data-visitor={data.visitor}>
                                        <Avatar user_id={data.visitor}/>
                                    </a>
                                </td>
                                <td className="time"><span className="af-clock me-2"></span>{data.date}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}