import { Component } from "react";
import userImage from '../asset/images/default_user.png';
import parse from "html-react-parser";
import Select from "react-select";
import Slider, { SliderTooltip } from "rc-slider";
import 'rc-slider/assets/index.css';
import axios from "axios";
import Alerts from "./alerts";
import config from '../config.json'

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

export default class NewFriends extends Component{

    constructor()
    {
        super()
        this.state = {
            search: '',
            showSettings:false,
            users: [],
            filter_city: '',
            filter_sex: '',
            filter_age: '',
            filter_intrest: '',
            filter_body: '',
            filter_children: '',
            filter_marage: '',
            filter_zodiak: '',
            filter_religion: '',
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users`)
            .then(res => {
                this.setState({
                    users: res.data,
                    filter_city: ''
                })
                console.log(res.data)
            })
    }

    calculateAge = birth =>{
        if( birth !== undefined ){
            let today = new Date();
            let birthDate = new Date(birth);
    
            let age_now = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if( m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ){
                age_now--;
            }
            return(age_now)
        }else{
            return null
        }
    }

    showUser = e => {
        e.preventDefault();
        const user = e.target.dataset.user;
        console.log(user);
        axios.post(`${config.dbURL}/wp-json/fac/v1/visits/${user}`,
        {
            visitor:sessionStorage.getItem('userID')
        }).then(res=>{
            localStorage.setItem('currentView', 'user-profile');
            localStorage.setItem('showUser', user);
            localStorage.setItem('showsCount', parseInt(localStorage.getItem('showsCount')) + 1 )
            window.location.reload(true);
        });
    }

    renderUsersList(users, index){
        let image = (users.user_avatar === undefined || users.user_avatar[0] === "") ? userImage:users.user_avatar;
        let user_name = users.user_nick[0];
        //console.log(user_name)
        //console.log(users.user_religion[0])
        //console.log(this.state.filter_religion)

        if(this.state.filter_city === '' && this.state.filter_sex === '' && this.state.filter_age === '' && this.state.filter_zodiak === '' && this.state.filter_children === '' && this.state.filter_marage === '' && this.state.filter_body === '' && this.state.filter_intrest === ''){
            return(
                <div className="u-col-12 u-col-md-5 px-3 mb-5 new_friends" key={index} data-index={index}>
                    <div className="user_prev d-flex">
                        <div className="u-col-6">
                            <img src={image} className="avatar" alt={user_name} />
                        </div>
                        <div className="user_data u-col-6 px-2">
                            <strong>{users.user_nick}</strong>
                            <ul>
                                {(users.user_birth === undefined || users.user_birth[0] === "")?'':parse(`<li>${this.calculateAge(users.user_birth[0])} lat</li>`)}
                                {(users.user_sex === undefined || users.user_sex['label'] === ""|| users.user_sex[0] === "")?'':parse(`<li>${users.user_sex['label']}</li>`)}
                                {(users.user_city === undefined || users.user_city[0] === "")?'':parse(`<li>${users.user_city[0]}</li>`)}
                                {(users.user_country['label'] === undefined || users.user_country['label'] === "")?'':parse(`<li>${users.user_country['label']}</li>`)}
                            </ul>
                            <a href="#" onClick={this.showUser} data-user={users.id}>
                                Obejrzyj profil
                            </a>
                        </div>
                    </div>
                </div>
            );
        }else{
            if(users.user_sex['value'] !== undefined 
            && users.user_body['value'] !== undefined
            && users.user_sex_intrest['value'] !== undefined
            && users.user_children['value'] !== undefined
            && users.user_zodiak['value'] !== undefined
            && users.user_status['value'] !== undefined
            ){
                if( this.state.filter_city.toLowerCase() === users.user_city[0].toLowerCase() 
                || this.state.filter_sex.toLowerCase() === users.user_sex['value'].toLowerCase() 
                || this.state.filter_body.toLowerCase() === users.user_body['value'].toLowerCase() 
                || this.state.filter_intrest.toLowerCase() === users.user_sex_intrest['value'].toLowerCase() 
                || this.state.filter_children.toLowerCase() === users.user_children['value'].toLowerCase() 
                || this.state.filter_zodiak.toLowerCase() === users.user_zodiak['value'].toLowerCase() 
                || this.state.filter_marage.toLowerCase() === users.user_status['value'].toLowerCase() 
                || this.state.filter_religion.toLowerCase() === users.user_religion[0].toLowerCase() 
                ){
                    console.log( this.state.filter_religion.toLowerCase() )
                    console.log( this.state.filter_sex.toLowerCase() )
                    console.log( users.user_religion[0].toLowerCase() )
                    return(
                        <div className="u-col-12 u-col-md-5 px-3 mb-5 new_friends" key={index} data-index={index}>
                            <div className="user_prev d-flex">
                                <div className="u-col-6">
                                    <img src={image} className="avatar" alt={user_name} />
                                </div>
                                <div className="user_data u-col-6 px-2">
                                    <strong>{users.user_nick}</strong>
                                    <ul>
                                        {(users.user_birth === undefined || users.user_birth[0] === "")?'':parse(`<li>${this.calculateAge(users.user_birth[0])} lat</li>`)}
                                        {(users.user_sex === undefined || users.user_sex['label'] === ""|| users.user_sex[0] === "")?'':parse(`<li>${users.user_sex['label']}</li>`)}
                                        {(users.user_city === undefined || users.user_city[0] === "")?'':parse(`<li>${users.user_city[0]}</li>`)}
                                        {(users.user_country['label'] === undefined || users.user_country['label'] === "")?'':parse(`<li>${users.user_country['label']}</li>`)}
                                    </ul>
                                    <a href="#" onClick={this.showUser} data-user={users.id}>
                                        Obejrzyj profil
                                    </a>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }

    }
    onchange = e => {
        this.setState({ search: e.target.value });
    };
    getReligion = e => {
        this.setState({ filter_religion: e.target.value });
    };
    searchSettings = () =>{
        this.setState({showSettings:true})
    }
    setFilterCity = (e) =>{
        this.setState({
            filter_city: e.target.value
        })
    }
    setSex = (user_sex) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_sex: user_sex.value
        })
    }
    handleSelectChildren = (user_child) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_children: user_child.value
        })
    }
    handleSelectChildren = (user_sex) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_intrest: user_sex.value
        })
    }
    handleSelectStatus = (user_sex) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_marage: user_sex.value
        })
    }
    handleSelectBody = (user_sex) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_body: user_sex.value
        })
    }
    handleChangeZodiak = (user_sex) =>{
        //console.log(user_sex.value)
        this.setState({
            filter_zodiak: user_sex.value
        })
    }
    closeSettingsWindow = () => {
        this.setState({
            showSettings:false,
        })
    }
    render(){
        const country = [
            {value:'AD', label:'Andora'},
            {value:'AE', label:'Zjednoczone Emiraty Arabskie łącznie z Abu Drabi, Dubai, Sharjah, Ajman, Umm al Qaiwain Ras al Khaimh i Fujairh '},
            {value:'AF', label:'Afganistan'},
            {value:'AG', label:'Antigua i Barbuda'},
            {value:'AI', label:'Anguilla'},
            {value:'AL', label:'Albania'},
            {value:'AM', label:'Armenia'},
            {value:'AO', label:'Angola łącznie z Kabinda'},
            {value:'AQ', label:'Antarktyda'},
            {value:'AR', label:'Argentyna'},
            {value:'AS', label:'Samoa Amerykańskie'},
            {value:'AT', label:'Austria'},
            {value:'AU', label:'Australia'},
            {value:'AW', label:'Aruba'},
            {value:'AZ', label:'Azerbejdżan'},
            {value:'BA', label:'Bośnia i Hercegowina'},
            {value:'BB', label:'Barbados'},
            {value:'BD', label:'Bangladesz'},
            {value:'BE', label:'Belgia'},
            {value:'BF', label:'Burkina Faso'},
            {value:'BG', label:'Bułgaria'},
            {value:'BH', label:'Bahrajn'},
            {value:'BI', label:'Burundi'},
            {value:'BJ', label:'Benin'},
            {value:'BM', label:'Bermudy'},
            {value:'BN', label:'Brunei Darussalam'},
            {value:'BO', label:'Boliwia'},
            {value:'BQ', label:'Bonaire, St Eustatius i Saba'},
            {value:'BR', label:'Brazylia'},
            {value:'BS', label:'Bahamy'},
            {value:'BT', label:'Bhutan'},
            {value:'BV', label:'Wyspa Bouveta'},
            {value:'BW', label:'Botswana'},
            {value:'BY', label:'Białoruś'},
            {value:'BZ', label:'Belize'},
            {value:'CA', label:'Kanada'},
            {value:'CC', label:'Wyspy Kokosowe (Keelinga)'},
            {value:'CD', label:'Kongo, Republika Demokratyczna'},
            {value:'CF', label:'Republika Środkowej Afryki'},
            {value:'CG', label:'Kongo'},
            {value:'CH', label:'Szwajcaria'},
            {value:'CI', label:'Wybrzeże Kości Słoniowej'},
            {value:'CK', label:'Wyspy Cooka'},
            {value:'CL', label:'Chile'},
            {value:'CM', label:'Kamerun'},
            {value:'CN', label:'Chiny'},
            {value:'CO', label:'Kolumbia'},
            {value:'CR', label:'Kostaryka'},
            {value:'CU', label:'Kuba'},
            {value:'CV', label:'Wyspy Zielonego Przylądka'},
            {value:'CW', label:'Curacao'},
            {value:'CX', label:'Wyspa Bożego Narodzenia'},
            {value:'CY', label:'Cypr'},
            {value:'CZ', label:'Republika Czeska'},
            {value:'DE', label:'Niemcy łącznie z wyspą Helgoland'},
            {value:'DJ', label:'Dżibuti'},
            {value:'DK', label:'Dania'},
            {value:'DM', label:'Dominika'},
            {value:'DO', label:'Republika Dominikany'},
            {value:'DZ', label:'Algieria'},
            {value:'EC', label:'Ekwador łącznie z wyspami Galapagos'},
            {value:'EE', label:'Estonia'},
            {value:'EG', label:'Egipt'},
            {value:'ER', label:'Erytrea'},
            {value:'ES', label:'Hiszpania łącznie z wyspami Baleary, Kanaryjskie oraz miastami Ceuta i Melilla w Afryce Pónocnej'},
            {value:'ET', label:'Etiopia'},
            {value:'FI', label:'Finlandia łącznie z wyspami Alandzkimi '},
            {value:'FJ', label:'Republika Fidżi'},
            {value:'FK', label:'Wyspy Falklandy (Malwiny)'},
            {value:'FM', label:'Federacja Mikronezji'},
            {value:'FO', label:'Wyspy Owcze'},
            {value:'FR', label:'Francja łącznie z Monako, Gujaną Francuską, Martyniką, Gwadelupą i Reunion, Saint Barthelemy, Saint Martin, Mayotte oraz Saint Pierre i Miquelon'},
            {value:'GA', label:'Gabon'},
            {value:'GB', label:'Wielka Brytania'},
            {value:'GD', label:'Grenada'},
            {value:'GE', label:'Gruzja'},
            {value:'GG', label:'Guernsey'},
            {value:'GH', label:'Ghana'},
            {value:'GI', label:'Gibraltar'},
            {value:'GL', label:'Grenlandia'},
            {value:'GM', label:'Gambia'},
            {value:'GN', label:'Gwinea'},
            {value:'GQ', label:'Gwinea Równikowa'},
            {value:'GR', label:'Grecja'},
            {value:'GS', label:'Georgia Poóudniowa i Sandwich Południowy'},
            {value:'GT', label:'Gwatemala'},
            {value:'GU', label:'Guam'},
            {value:'GW', label:'Gwinea Bissau'},
            {value:'GY', label:'Gujana'},
            {value:'HK', label:'Hongkong'},
            {value:'HM', label:'Wyspa Heard i Wyspy McDonald'},
            {value:'HN', label:'Honduras'},
            {value:'HR', label:'Chorwacja'},
            {value:'HT', label:'Haiti'},
            {value:'HU', label:'Węgry'},
            {value:'ID', label:'Indonezja'},
            {value:'IE', label:'Irlandia'},
            {value:'IL', label:'Izrael'},
            {value:'IM', label:'Wyspa Man'},
            {value:'IN', label:'India'},
            {value:'IO', label:'Brytyjskie Terytorium Oceanu Indyjskiego łącznie z wyspami Czagos'},
            {value:'IQ', label:'Irak'},
            {value:'IR', label:'Iran'},
            {value:'IS', label:'Islandia'},
            {value:'IT', label:'Włochy'},
            {value:'JE', label:'Jersey '},
            {value:'JM', label:'Jamajka'},
            {value:'JO', label:'Jordania'},
            {value:'JP', label:'Japonia'},
            {value:'KE', label:'Kenia'},
            {value:'KG', label:'Kirgistan'},
            {value:'KH', label:'Kambodża (Kampucza)'},
            {value:'KI', label:'Kiribati'},
            {value:'KM', label:'Komory'},
            {value:'KN', label:'Saint Kitts i Nevis'},
            {value:'KP', label:'Koreańska Republika Ludowo-Demokratyczna (Korea Pónocna)'},
            {value:'KR', label:'Republika Korei (Korea Południowa)'},
            {value:'KW', label:'Kuwejt'},
            {value:'KY', label:'Kajmany'},
            {value:'KZ', label:'Kazachstan'},
            {value:'LA', label:'Laos'},
            {value:'LB', label:'Liban'},
            {value:'LC', label:'Saint Lucia'},
            {value:'LI', label:'Lichtenstein'},
            {value:'LK', label:'Sri Lanka'},
            {value:'LR', label:'Liberia'},
            {value:'LS', label:'Lesotho'},
            {value:'LT', label:'Litwa'},
            {value:'LU', label:'Luksemburg'},
            {value:'LV', label:'Łotwa'},
            {value:'LY', label:'Libia'},
            {value:'MA', label:'Maroko łącznie Saharą Zachodnią'},
            {value:'MD', label:'Mołdowa'},
            {value:'ME', label:'Czarnogóra'},
            {value:'MG', label:'Madagaskar'},
            {value:'MH', label:'Wyspy Marshalla'},
            {value:'MK', label:'Macedonia'},
            {value:'ML', label:'Mali'},
            {value:'MM', label:'Myanmar (Birma)'},
            {value:'MN', label:'Mongolia'},
            {value:'MO', label:'Makao'},
            {value:'MP', label:'Mariany Północne'},
            {value:'MR', label:'Mauretania'},
            {value:'MS', label:'Montserrat'},
            {value:'MT', label:'Malta'},
            {value:'MU', label:'Mauritius'},
            {value:'MV', label:'Malediwy'},
            {value:'MW', label:'Malawi'},
            {value:'MX', label:'Meksyk'},
            {value:'MY', label:'Malezja'},
            {value:'MZ', label:'Mozambik'},
            {value:'NA', label:'Namibia'},
            {value:'NC', label:'Nowa Kaledonia'},
            {value:'NE', label:'Niger'},
            {value:'NF', label:'Wyspa Norfolk'},
            {value:'NG', label:'Nigeria'},
            {value:'NI', label:'Nikaragua'},
            {value:'NL', label:'Niderlandy'},
            {value:'NO', label:'Norwegia łącznie z wyspami Svalbard i Jan Mayen'},
            {value:'NP', label:'Nepal'},
            {value:'NR', label:'Nauru'},
            {value:'NU', label:'Niue'},
            {value:'NZ', label:'Nowa Zelandia'},
            {value:'OM', label:'Oman łącznie z wyspami Kuria Muria'},
            {value:'PA', label:'Panama'},
            {value:'PE', label:'Peru'},
            {value:'PF', label:'Polinezja Francuska'},
            {value:'PG', label:'Papua Nowa Gwinea'},
            {value:'PH', label:'Filipiny'},
            {value:'PK', label:'Pakistan'},
            {value:'PN', label:'Pitcairn'},
            {value:'PL', label:'Polska'},
            {value:'PS', label:'Palestyna'},
            {value:'PT', label:'Portugalia łącznie z wyspami Acores (Azory) i Madeira'},
            {value:'PW', label:'Palau'},
            {value:'PY', label:'Paragwaj'},
            {value:'QA', label:'Katar'},
            {value:'RO', label:'Rumunia'},
            {value:'RS', label:'Serbia'},
            {value:'RU', label:'Federacja Rosyjska'},
            {value:'RW', label:'Rwanda'},
            {value:'SA', label:'Arabia Saudyjska'},
            {value:'SB', label:'Wyspy Salomona'},
            {value:'SC', label:'Seszele'},
            {value:'SD', label:'Sudan'},
            {value:'SE', label:'Szwecja'},
            {value:'SG', label:'Singapur'},
            {value:'SH', label:'Wyspa Świętej Heleny'},
            {value:'SI', label:'Słowenia'},
            {value:'SK', label:'Słowacja'},
            {value:'SL', label:'Sierra Leone'},
            {value:'SM', label:'San Marino'},
            {value:'SN', label:'Senegal'},
            {value:'SO', label:'Somalia'},
            {value:'SR', label:'Surinam'},
            {value:'ST', label:'Wyspy Świętego Tomasza i Ksi���ca'},
            {value:'SV', label:'Salwador'},
            {value:'SX', label:'Sint Maarten'},
            {value:'SY', label:'Syria'},
            {value:'SZ', label:'Swaziland'},
            {value:'TC', label:'Wyspy Turks i Caicos'},
            {value:'TD', label:'Czad'},
            {value:'TF', label:'Francuskie Terytoria Południowe'},
            {value:'TG', label:'Togo'},
            {value:'TH', label:'Tajlandia'},
            {value:'TJ', label:'Tadżykistan'},
            {value:'TK', label:'Tokelau'},
            {value:'TL', label:'Timor Wschodni'},
            {value:'TM', label:'Turkmenistan'},
            {value:'TN', label:'Tunezja'},
            {value:'TO', label:'Tonga'},
            {value:'TR', label:'Turcja'},
            {value:'TT', label:'Trynidad i Tobago'},
            {value:'TV', label:'Tuvalu'},
            {value:'TW', label:'Tajwan'},
            {value:'TZ', label:'Tanzania łącznie z wyspami Tanganika, Zanzibar i Pemba'},
            {value:'UA', label:'Ukraina'},
            {value:'UG', label:'Uganda'},
            {value:'UM', label:'Terytoria Zamorskie Stanów Zjednoczonych'},
            {value:'US', label:'Stany Zjednoczone Ameryki Północnej łącznie z Portoryko i Navassa'},
            {value:'UY', label:'Urugwaj'},
            {value:'UZ', label:'Uzbekistan'},
            {value:'VA', label:'Watykan'},
            {value:'VC', label:'Saint Vincent i Grenadyny'},
            {value:'VE', label:'Wenezuela'},
            {value:'VG', label:'Brytyjskie Wyspy Dziewicze'},
            {value:'VI', label:'Wyspy Dziewicze Stanów Zjednoczonych'},
            {value:'VN', label:'Wietnam'},
            {value:'VU', label:'Vanuatu'},
            {value:'WF', label:'Wallis i Futuna'},
            {value:'WS', label:'Samoa'},
            {value:'XK', label:'Kosowo'},
            {value:'YE', label:'Jemen łącznie z wyspami Perim, Kamaran, Socotra'},
            {value:'ZA', label:'Republika Południowej Afryki'},
            {value:'ZM', label:'Zambia'},
            {value:'ZW', label:'Zimbabwe'}
        ]  
        const marks = {
            0:'0km',
            150:'150km'
        }
        const { search } = this.state;
        /* const filteredUsers = this.state.users.filter(users => {
            return users.user_nick.toLowerCase().indexOf(search.toLowerCase()) !== -1
        }); */
        const filteredUsers = this.state.users.filter(users => {
            return users.user_nick[0].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        });
        //console.log( this.state.users )
        if( localStorage.getItem('showsCount') < 0 ){
            return(
                <div className="u-col-12">
                    <Alerts type="info" message="Wykup wariant premium, aby móc przeglądać dalej."/>
                </div>
            )
        }else{
            return(
                <>
                <div className="text-center my-4 premium">
                    PREMIUM
                    <p>Wybierz wariant premium aby wyszukać znajomych nicku więcej niż 5 razy w miesiącu.</p>
                </div>
                <div className="mb-5">
                    <div className="text-center">
                        <input type="text" placeholder="Wpisz nick" onChange={this.onchange}/>
                    </div>
                    <button className="link-button my-4" onClick={this.searchSettings}>otwórz ustawienia szczegółowego wyszukiwania&nbsp;<span className="af-arrow-right"></span></button>
                    <div className="u-row position-relative">
                        {filteredUsers.map((users,index) => {return this.renderUsersList(users,index);})}
                        <div className="u-col-12">
                            <div className={(this.state.showSettings)?'search_settings d-block':'search_settings d-none'}>
                                <div className="u-row">
                                    <div className="u-col-11">
                                        <span className="title">
                                            ustawienia szczegółowego wyszukiwanie
                                        </span>
                                    </div>
                                    <div className="u-col-1 text-right">
                                        <button className="close_popup" onClick={this.closeSettingsWindow}><span class="af-close"></span></button>
                                    </div>
                                </div>
                                <div className="u-row pt-3 pt-md-5">
                                    <div className="u-col-12 u-col-md-5">
                                        <h5>Płeć</h5>
                                        <Select  
                                            name="filter_sex" 
                                            options={[
                                                {value:'kobieta',label:'Kobieta'},
                                                {value:'mezczyzna',label:'Mężczyzna'},
                                                {value:'inne',label:'Inne'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.setSex}
                                            value={this.state.filter_sex}
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-5 offset-md-2">
                                        <h5>Wyznanie</h5>
                                        <input type="text" name="filter_religion" onChange={this.getReligion} value={this.state.filter_religion}/>
                                    </div>
                                    <div className="u-col-12 u-col-md-5">
                                        <h5>Wiek</h5>
                                        <input type="text"/>
                                    </div>
                                    <div className="u-col-12 u-col-md-5 offset-md-2">
                                        <h5>Dzieci</h5>
                                        <Select  
                                            name="filter_children" 
                                            options={[
                                                {value:'tak',label:'Tak'},
                                                {value:'nie',label:'Nie'},
                                                {value:'chce',label:'Nie, ale chce mieć'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleSelectChildren}
                                            value={this.state.filter_children}
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-5">
                                        <h5>Wykazuje zainteresowania</h5>
                                        <Select  
                                            name="filter_intrest" 
                                            options={[
                                                {value:'wszyscy',label:'Wszyscy'},
                                                {value:'kobiety',label:'Kobiety'},
                                                {value:'mezczyzni',label:'Mężczyźni'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleSelectSexIntrest}
                                            value={this.state.filter_intrest}
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-5 offset-md-2">
                                        <h5>Stan cywilny</h5>
                                        <Select  
                                            name="filter_marage" 
                                            options={[
                                                {value:'panna',label:'Panna'},
                                                {value:'kawaler',label:'Kawaler'},
                                                {value:'zwiazek',label:'W związku'},
                                                {value:'rozwod',label:'Po rozwodzie'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleSelectStatus}
                                            value={this.state.filter_marage}
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-5">
                                        <h5>Preferowany typ sylwetki</h5>
                                        <Select  
                                            name="filter_body" 
                                            options={[
                                                {value:'atletyczna',label:'Atletyczna'},
                                                {value:'szczuply',label:'Szczupła'},
                                                {value:'srednia',label:'Średnia'},
                                                {value:'gruby',label:'Parę kilo za dużo'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleSelectBody}
                                            value={this.state.filter_body}
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-5 offset-md-2">
                                        <h5>Znak zodiaku</h5>
                                        <Select  
                                            name="filter_zodiak" 
                                            options={[
                                                {value:'wodnik',label:'Wodnik'},
                                                {value:'ryby',label:'Ryby'},
                                                {value:'baran',label:'Baran'},
                                                {value:'byk',label:'Byk'},
                                                {value:'bliznieta',label:'Bliźnięta'},
                                                {value:'rak',label:'Rak'},
                                                {value:'panna',label:'Panna'},
                                                {value:'waga',label:'Waga'},
                                                {value:'skorpion',label:'Skorpion'},
                                                {value:'strzelec',label:'Strzelec'},
                                                {value:'koziorozec',label:'Koziorożec'}
                                            ]} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleChangeZodiak}
                                            value={this.state.filter_zodiak}
                                        />
                                    </div>
                                    <div className="u-col-12">
                                        <h5>Zainteresowania</h5>
                                        <div className="custom_checkbox_badge my-4">
                                            <label><input type="checkbox" name="user_interest" value="1" /><span>Kulinaria</span></label>
                                            <label><input type="checkbox" name="user_interest" value="2" /><span>Motoryzacja</span></label>
                                            <label><input type="checkbox" name="user_interest" value="3" /><span>Sztuka</span></label>
                                            <label><input type="checkbox" name="user_interest" value="4" /><span>Muzyka</span></label>
                                            <label><input type="checkbox" name="user_interest" value="5" /><span>Ogród</span></label>
                                            <label><input type="checkbox" name="user_interest" value="6" /><span>Majsterkowanie</span></label>
                                            <label><input type="checkbox" name="user_interest" value="7" /><span>Taniec</span></label>
                                            <label><input type="checkbox" name="user_interest" value="8" /><span>Bieganie</span></label>
                                            <label><input type="checkbox" name="user_interest" value="9" /><span>Sport</span></label>
                                            <label><input type="checkbox" name="user_interest" value="10" /><span>Filmy i seriale</span></label>
                                            <label><input type="checkbox" name="user_interest" value="11" /><span>Netflix</span></label>
                                            <label><input type="checkbox" name="user_interest" value="12" /><span>Sporty wodne</span></label>
                                            <label><input type="checkbox" name="user_interest" value="13" /><span>Śpiew</span></label>
                                        </div>
                                    </div>
                                    <div className="u-col-12 u-col-md-6 pr-md-4">
                                        <h5>Promień wyszukiwania</h5>
                                        <p className="lead">
                                            Określ odległość w których chcesz wyszukiwać nowe znajomości.
                                        </p>
                                        <Range 
                                            min={0}
                                            max={150}
                                            step={5}
                                            defaultValue={[15,100]}
                                            onChange={this.handleChange}
                                            name="user_search"
                                            className="pk_range"
                                            marks={marks}
                                            tipFormatter={
                                                value => `${value} km`
                                            }
                                        />
                                    </div>
                                    <div className="u-col-12 u-col-md-6 pl-md-4">
                                        <h5>Miejscowość wyszukiwania</h5>
                                        <p className="lead">
                                        Określ miejscowość w której chcesz wyszukiwać osoby.
                                        </p>
                                        <Select  
                                            name="user_country" 
                                            options={country} 
                                            placeholder='wybierz'
                                            className="multi-select"
                                            onChange={this.handleSelectCountry}
                                            value={this.state.user_country}
                                        />
                                        <input type="text" placeholder="Miasto" onChange={this.setFilterCity}/>
                                    </div>
                                    <div className="u-col-12 text-center mt-3">
                                        <button className="btn btn-voilet" onClick={this.closeSettingsWindow}>
                                            Zastosuj <span className="af-arrow-right"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        }
    }
}