import { Component } from "react";
import Avatar from "./Avatar";
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from '../config.json'

export default class UserTable extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    friendAccept = e =>{
        //change status
        let friend = e.target.dataset.friend;
        const currUser = sessionStorage.getItem('userID');
        axios
            .post(`${config.dbURL}/wp-json/fac/v1/friend/${sessionStorage.getItem('userID')}`,
            {
                visitor:friend,
                status:'1'
            }
            )
        axios.
            post(`${config.dbURL}/wp-json/fac/v1/notification/${friend}`,{
            content: 'Twoje zaproszenie zostało zaakceptowane.',
            type: 'acceptfriends'
            }).then(response=>{
                //redirect
                localStorage.setItem('currentView','call-in')
                window.location.reload(true)
            }
            )
    }
    fiendDelete = () =>{
        //change status
    
        //send notification
    }
    goToVisitor = e => {
        e.preventDefault();
        localStorage.setItem('showUser', e.target.dataset.visitor);
        localStorage.setItem('currentView','user-profile');
        window.location.reload(true);
    }
    render(){
        const data = this.props.data
        return(
            <table className="users_list">
                <thead>
                    <tr>
                        <th colSpan="3">
                            {this.props.label}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map( (data, index) => 
                        <tr key={index}>
                            <td className="user">
                                <a href="#" onClick={this.goToVisitor} data-visitor={data.author}>
                                    <Avatar user_id={data.author}/>
                                </a>
                            </td>
                            <td className="action">
                                <button className="btn btn-default" onClick={this.friendAccept} data-friend={data.author}>Akceptuj</button>
                                <button className="btn btn-outline" onClick={this.fiendDelete} data-friend={data.author}>Odrzuć</button>
                            </td>
                            <td className="time"><span className="af-clock me-2"></span>{data.time}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }
}