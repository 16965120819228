import Select from "react-select";

function Step15(props)
{
if( props.currentStep !== 15 ){
    return null
}
    return(
        <div>
            <h3 className="header">Stan cywilny</h3>
            <p className="lead mb-5">Wybierz z listy swój stan cywilny.</p>
            <Select  
                name="user_status" 
                options={[
                    {value:'panna',label:'Panna'},
                    {value:'kawaler',label:'Kawaler'},
                    {value:'zwiazek',label:'W związku'},
                    {value:'rozwod',label:'Po rozwodzie'}
                ]} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_status}
            />
        </div>
    );
}

export default Step15;