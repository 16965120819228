import { Component } from "react";
import Alerts from "./alerts";

export default class ReportBug extends Component{

    state = {
        user_nick: sessionStorage.getItem('userName'),
        email: sessionStorage.getItem('userEmail'),
        subject: '',
        message: '',
        acceptance: '',
        errors: [],
    }

    handleChange = event =>{
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }
    componentDidMount(){
        this.setState(
            {
                errors:[]
            }
        )
    }
    sendForm = e =>{
        e.preventDefault();
        this.setState(
            {
                errors:['success', 'Dziękujemy za wysłanie zgłoszenia.']
            }
        )
        console.log('wyślij wiadomość')
    }

    render(){
        if( this.state.errors.length > 0 ){
            return(
                <div className="account_box p-5">
                    <h2 className="text-center">Zgłoszenie usterki technicznej</h2>
                    <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                </div>
            )
        }else{
            return(
                <div className="account_box py-4">
                        <h1 className="text-center">Zgłoszenie usterki technicznej</h1>
                        <div className="box_shadow p-5">
                            <form className="full-form">
                                <label className="d-block">
                                    Twój nick<br/>
                                    {this.state.user_nick}
                                    <input type="hidden" name="user" value={this.state.user_nick}/>
                                    <input type="hidden" name="email" value={this.state.email}/>
                                </label>
                                <label htmlFor="subject" className="d-block">
                                    Temat
                                </label>
                                    <input name="subject" type="text" placeholder="Problem techniczny" className="d-block" value={this.state.subject} onChange={this.handleChange} />
                                <label htmlFor="message" className="d-block">
                                    Wiadomość
                                </label>
                                    <textarea name="message" placeholder="" className="d-block" value={this.state.message} onChange={this.handleChange}></textarea>
                                <div className="custom_checkbox">
                                    <input type="checkbox" name="acceptance" id="acceptance"/>
                                    <label htmlFor="acceptance">
                                        Zapoznałem się z informacją o administratorze i przetwarzaniu danych
                                    </label>
                                </div>
                                <div className="text-md-right">
                                    <button className="send" onClick={this.sendForm} type="submit">Wyślij</button>
                                </div>
                            </form>
                        </div>
                    </div>
            )
        }
    }
}