import React,{ Component } from "react";
import axios from "axios";
import Loader from './loader';
import PostItem from './PostItem';
import Alerts from "./alerts";
import config from '../config.json'

export default class MyPosts extends Component{
    state = {
        posts: [],
        isLoaded: false
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/post`)
            //.get('${config.dbURL}/feelio/wp-json/wp/v2/posts')
            .then(res => this.setState({
                posts: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));
        }
        
        render(){
            const {posts, isLoaded} = this.state;
            let post_couter=0;
            //console.log( this.state )
            if( isLoaded ){
                if( posts.length > 0 ){
                    return(
                        <div className="u-row">
                            {posts.map( (post, index) => {
                                if( post.author === sessionStorage.getItem('userID') ){
                                    post_couter++;
                                    return <PostItem key={index} post={post}/>
                                }
                            } )}
                            {console.log(post_couter)}
                            {(post_couter === 0)?<div className="u-col-12">
                                <Alerts type='info' message='Nie opublikowałeś jeszcze żadnego wpisu.' />
                            </div>:null}
                        </div>
                    );
                    
                }else{
                    return(
                        <div className="u-row">
                            <div className="u-col-12">
                                <Alerts type='info' message='Brak wpisów.' />
                            </div>
                        </div>
                    );
                }
            }else{
                return <Loader />
            }
    }
}