function Step8(props)
{
if( props.currentStep !== 8 ){
    return null
}
    return(
        <div>
            <h3 className="header">Gdzie <br/>pracujesz?</h3>
            <p className="lead">Wpisz swoje stanowisko pracy lub status zawodowy.</p>
            <input type="text" name="user_work"  value={props.user_work} onChange={props.handleChange} />
        </div>
    );
}

export default Step8;