import { Component } from "react";
import axios from "axios";
import parse from "html-react-parser";
import config from '../config.json'

const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];


export default class MyGallery extends Component{

    state = {
        content:''
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/gallery/${sessionStorage.getItem('userID')}`)
            .then(res=>{
                if(res.data.code !== 'no_data'){
                    this.setState({
                        content:res.data[0].gallery
                    })
                }
                console.log(res.data[0].gallery.split("<div class=\"gallery-prev\">"))
            })
    }

    _handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState({
            content: this.state.content+'<div class="u-col-6 u-col-md-3 p-3 gallery_item"><div class="gallery-prev"><span class="delete">-</span><img src="'+reader.result+'" /></div></div>'
          });
          axios
            .post(`${config.dbURL}/wp-json/fac/v1/gallery/${sessionStorage.getItem('userID')}`, {content:this.state.content})
            .then(res=>{
                console.log(res)
            })
            console.log(this.state.content)
        }
    
        reader.readAsDataURL(file)
    }

    render(){

        return(
            <div className="account_box px-3 py-4 my-gallery">
                <div className="u-row">
                    <div className="u-col-12 u-col-md-8 mx-auto">
                        <h2 className="text-center">Moja Galeria</h2>
                    </div>
                    <div style={{width:'100%',marginBottom:'1rem'}}></div>
                    <div className="u-col-6 u-col-md-3 p-3">
                        <div className="previewComponent d-block">
                            <label className="insertPostImage">
                                <input className="fileInput" 
                                    type="file" 
                                    onChange={(e)=>this._handleImageChange(e)} />
                            </label>
                        </div>
                    </div>
                    {parse(this.state.content)}
                </div>
            </div>
        )
    }
}