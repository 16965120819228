import Select from "react-select";
export default function Step12(props) {
    if( props.currentStep !== 12 ){
        return null
    }
    return(
        <div>
            <h3 className="header">Twoja sylwetka...</h3>
            <p className="lead">Wybierz typ swojej sylwetki.</p>
            <Select  
                name="user_body" 
                options={[
                    {value:'atletyczna',label:'Atletyczna'},
                    {value:'szczuply',label:'Szczupła'},
                    {value:'srednia',label:'Średnia'},
                    {value:'gruby',label:'Parę kilo za dużo'}
                ]} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_body}
            />
        </div>
    );
}