import React,{ Component } from "react";
import axios from "axios";
import Avatar from "./Avatar";
import Editor,{createEditorStateWithText} from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import {stateToHTML} from 'draft-js-export-html';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/emoji/lib/plugin.css';
import parse from "html-react-parser";
import config from '../config.json'

const emojiPlugin = createEmojiPlugin();
const imagePlugin = createImagePlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const plugins = [emojiPlugin, imagePlugin];
const text = '';

export default class CreatePost extends Component{

    state = {
        post_title:'',
        post_content:null,
        showEmoji:false,
        editorState: createEditorStateWithText(text),
        image: false
    }

    onChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    _handleImageChange(e) {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState({
            image: true,
            content: '<img src="'+reader.result+'" />'
          });
          console.log( reader.result )
        }
    
        reader.readAsDataURL(file)
    }

    focus = () => {
        this.editor.focus()
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
    }

    insertPost = e => {
        e.preventDefault()
        let newPost = {};
        if(this.state.image){
            newPost = {
                author: sessionStorage.getItem('userID'),
                title: this.state.post_title,
                content: this.state.content,
                image: true
            };
        }else{
            newPost = {
                author: sessionStorage.getItem('userID'),
                title: this.state.post_title,
                content: stateToHTML(this.state.editorState.getCurrentContent()),
                image: false
            };
        }
        console.log(newPost)
        axios.post(`${config.dbURL}/wp-json/fac/v1/post/`, newPost).then( res => {
            localStorage.setItem('currentView', 'all-posts');
            window.location.reload(true);
        })
    }

    handleBack = () => {
        localStorage.setItem('currentView', 'all-posts');
        window.location.reload();
    }
    addEmoji = () =>{
        
    }
    showEmoji = () =>{
        const currentState = this.state.activeSettings;
        this.setState({
            showEmoji: !currentState,
        });
    }
    render(){
        let {content} = this.state;
        let $imagePreview = null;
        if (this.state.image) {
          $imagePreview = (content);
        }
        console.log(content)
        return(
            <div className="new_post_form">
                <div className="u-row align-items-center">
                    <div className="u-col-6">
                        <Avatar user_id={sessionStorage.getItem('userID')} />
                    </div>
                    <div className="u-col-6 text-right">
                        <button className="close go-back" aria-label="Zamknij" onClick={this.handleBack}>
                            <span className="af-cancel"></span>
                        </button>
                    </div>
                </div>
                <input type="text" name="post_title" value={this.state.post_title} placeholder="Wpisz tytuł posta" onChange={this.handleChange}/>
                {/* <textarea name="post_content" value={this.state.post_content} placeholder="Kliknij aby zacząć pisać treść posta..." readOnly></textarea> */}
                {/* <div className="textarea" contentEditable={true} onInput={this.textType} dangerouslySetInnerHTML={{__html: Emoji({html:true, size:16, emoji:['+1','joy'], set:"apple"})}}></div> */}
                {(!this.state.image)?
                <div className="textarea" onClick={this.focus}>
                <Editor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    plugins={plugins}
                    ref={(element) => {
                        this.editor = element;
                    }}
                />
                </div>:
                <div className="imgPreview">
                    {parse(this.state.content)}
                </div>
                }
                
                <div className="post-tools">
                    <div className="previewComponent d-inline-block">
                        <label className="insertPostImage">
                            <span className="af-camera"></span>
                            <input className="fileInput" 
                                type="file" 
                                onChange={(e)=>this._handleImageChange(e)} />
                        </label>
                    </div>
                    <EmojiSelect />
                    {/* <button className={(this.state.showEmoji)?'emoticon active':'emoticon'} title="Dodaj emotikon" onClick={this.showEmoji}><span className="af-smile"></span><Emoticon/></button> */}
                </div>
                <div className="text-right">
                    <button className="goforward" type="submit" aria-label="Opublikuj" onClick={this.insertPost}>Opublikuj&nbsp;<span className="af-arrow-right"></span></button>
                </div>
            </div>
        );
    }
}