import { Component } from "react";
import Avatar from "./Avatar";
import axios from "axios";
import Editor,{createEditorStateWithText} from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import {stateToHTML} from 'draft-js-export-html';
import parse from "html-react-parser";
import SmallLoader from "./smaill_loader";
import config from '../config.json'

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const plugins = [emojiPlugin];
const text = '';

export default class Comments extends Component{
    constructor(props){
        super(props)
        this.state = {
            comments:[],
            commentActive:false,
            comment_message: '',
            author:'',
            editorState: createEditorStateWithText(text),
            isLoaded: false
        }
    }

    componentDidMount(){
        axios({method:'get',url:`${config.dbURL}/wp-json/fac/v1/comments/${this.props.post_id}`})
            .then(res => {
                this.setState({
                    comments:res.data,
                    isLoaded:true
                });
            })
            .catch(err=>{
                console.warn(err);
            });
    }

    componentDidUpdate(){
        axios({method:'get',url:`${config.dbURL}/wp-json/fac/v1/comments/${this.props.post_id}`})
            .then(res => {
                this.setState({
                    comments:res.data,
                    isLoaded:true
                });
            })
            .catch(err=>{
                console.warn(err);
            });
    }

    onChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    focus = () => {
        this.editor.focus()
    }

    makeHumanDate(date){
        date = date.split("-")
        var month = ''
        switch( date[1] ){
            case "01":
                month = "STY"
            break;
            case "02":
                month = "LUT"
            break;
            case "03":
                month = "MAR"
            break;
            case "04":
                month = "KWI"
            break;
            case "05":
                month = "MAJ"
            break;
            case "06":
                month = "CZE"
            break;
            case "07":
                month = "LIP"
            break;
            case "08":
                month = "SIE"
            break;
            case "09":
                month = "WRZ"
            break;
            case "10":
                month = "PAŹ"
            break;
            case "11":
                month = "LIS"
            break;
            case "12":
                month = "GRU"
            break;
            default:
                month = "STY"
            break;
        }
        return date[2]+" "+month+" "+date[0]
    }

    makeCommentPost(comment, key){
        return(
            <li key={key}>
                <header>
                    <Avatar user_id={comment.author}/>
                    <div className="data-time">
                        <span className="af-clock">&nbsp;{comment.created.split(" ")[1]}</span>
                        <span className="data">{ this.makeHumanDate( comment.created.split(" ")[0] ) }</span>
                    </div>
                </header>
                <aside>
                    {parse(comment.content)}
                </aside>
            </li>
        );
    }

    showCommentWindow = () =>{
        this.setState({
            commentActive:true
        });
    }
    hideCommentWindow = () =>{
        this.setState({
            commentActive:false
        });
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
    }

    insertComment = e => {
        e.preventDefault()
        axios
        .post(`${config.dbURL}/wp-json/fac/v1/comments/${this.props.post_id}`, {
                id: this.props.post_id,
                author:sessionStorage.getItem('userID'),
                content: stateToHTML(this.state.editorState.getCurrentContent())
            })
            .then(res => {
                this.setState({
                    comment_message:'',
                    commentActive:false,
                    isLoaded:false
                })
            })
            .catch(err => {
                this.setState({
                    comment_message:'',
                    commentActive:false,
                    isLoaded:false
                })
                console.warn(err)
            })
    }

    render(){
        const {comments} = this.state
        if( !this.state.isLoaded ){
            return <SmallLoader content="Ładuję komentarze"/>
        }else{
            return(
                <div className="comment-form">
                    <ol className="comment-list">
                        {(comments === "")?null:comments.map( (comment, key) => (this.makeCommentPost(comment, key)) )}
                    </ol>
                    <div className="text-right my-4">
                        <button className="button-outline" onClick={this.showCommentWindow}>napisz komentarz</button>
                    </div>
                    <div className={this.state.commentActive?'add_comment active':'add_comment'}>
                        <div className="content">
                            <div className="text-right">
                                <button className="close" aria-label="Zamknij" onClick={this.hideCommentWindow}>
                                    <span className="af-cancel"></span>
                                </button>
                            </div>
                            <form>
                                <Avatar user_id={sessionStorage.getItem('userID')}/>
                                {/* <textarea name="comment_message" placeholder="Twój komentarz" value={this.state.comment_message} onChange={this.handleChange}></textarea> */}
                                <Editor
                                    editorState={this.state.editorState}
                                    onChange={this.onChange}
                                    plugins={plugins}
                                    ref={(element) => {
                                        this.editor = element;
                                    }}
                                />
                                <div className="u-row">
                                    <div className="u-col-6">
                                        <EmojiSelect />
                                    </div>
                                    <div className="u-col-6 text-right">
                                        <button className="goforward" type="submit" aria-label="Opublikuj" onClick={this.insertComment}>Opublikuj&nbsp;<span className="af-arrow-right"></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}