import React, {useState} from 'react';
import GoogleLogin from 'react-google-login';
//import parse from "html-react-parser";
import LogIn from './component/LogIn';
import axios from 'axios';
import AppView from './component/appView';
import Onbording from './component/onbording';
import logo from './asset/logo-color.svg';
import logo_light from './asset/logo-white.svg';
import './asset/bootstrap-reboot.css'
import './asset/bootstrap-grid.css'
import './asset/fonts/css/feelio.css';
import './App.css';
import config from './config.json'
//import { render } from 'react-dom';

function userLogOut(e){
  e.preventDefault();
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
}
function goWinBack(e){
  localStorage.setItem('currentView', sessionStorage.getItem('oldView'));
  window.location.reload();
}
function showGoWinBack(e){
  if( 
    localStorage.getItem('currentView') !== 'room' && 
    localStorage.getItem('currentView') !== 'create-posts' && 
    localStorage.getItem('currentView') !== 'report-user' && 
    localStorage.getItem('currentView') !== 'report-bug' && 
    //localStorage.getItem('currentView') !== 'new-friends' && 
    localStorage.getItem('currentView') !== 'all-posts' &&
    localStorage.getItem('currentView') !== 'post-view'
    ){
    return(
      <button className="go-back" onClick={goWinBack}><span className="af-arrow-right icon-rotate-x"></span>Wróć</button>
    );
  }
  else{
    return null
  }
}
function showAllPosts(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'all-posts');
  window.location.reload();
}
function showMyPosts(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'my-posts');
  window.location.reload();
}
function showCreatePosts(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'create-posts');
  window.location.reload();
}

function showMyProfile(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'my-profile');
  window.location.reload();
}
function showEditProfile(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'edit-profile');
  window.location.reload();
}
function showMyGallery(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'my-gallery');
  window.location.reload();
}
function showCallIn(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'call-in');
  window.location.reload();
}
function showCallOut(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'call-out');
  window.location.reload();
}
function showMyFriends(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'my-friends');
  window.location.reload();
}

function showProSubscribe(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'pro-subscribe');
  window.location.reload();
}
function showProSettings(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'pro-settings');
  window.location.reload();
}

function showReportUser(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'report-user');
  window.location.reload();
}
function showReportBug(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'report-bug');
  window.location.reload();
}

function showNewFriends(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'new-friends');
  window.location.reload();
}
function showChat(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'chat');
  window.location.reload();
}
function showNotyfications(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'notyfications');
  window.location.reload();
}
function showVisit(e){
  sessionStorage.setItem('oldView',localStorage.getItem('currentView'))
  localStorage.setItem('currentView', 'visit');
  window.location.reload();
}

function App() {
  let [menuItem, setMenuItem] = useState('');
  let [chatActive, setChatActive] = useState('');
  let [user_avatar, setUserAvatar] = useState('');
  let [have_notyfi, sethaveNotyfi] = useState(false);
  let [have_chat_notyfi, setHaveChatNotyfi] = useState(false);
  let [user_visit, setUserVisit] = useState("0");
  if( sessionStorage.getItem('userloggedIn') ){
    axios
      .get(`${config.dbURL}/wp-json/fac/v1/users/${sessionStorage.getItem('userID')}`)
      .then(res =>{
        setUserAvatar(res.data.user_avatar);
        sessionStorage.setItem( 'userName', res.data.user_nick );
        //console.log(user_avatar)
      })
      .catch(err => {
          console.warn(err)
      })
    axios
      .get(`${config.dbURL}/wp-json/fac/v1/visits/${sessionStorage.getItem('userID')}`)
      .then(response => {
        if(response.data[0] === undefined){
          setUserVisit(0)
        }else{
          setUserVisit(response.data[0].count)
        }
        //console.log(response.data[0])
        //console.log(user_visit)
      })
    axios
      .get(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`)
      .then(responses => {
        //console.log(responses.data[0].type)
        if( responses.data.length > 0 ){
          sethaveNotyfi(true)
          responses.data.map((data)=>{
            if( data.type === "chat" ){
              setHaveChatNotyfi(true)
            }
          })
        }
      })
  }
  setInterval(() =>{
    axios
      .get(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`)
      .then(responses => {
        if( responses.data.length > 0 ){
          sethaveNotyfi(true)
          responses.data.map((data)=>{
            if( data.type === "chat" ){
              setHaveChatNotyfi(true)
            }
          })
        }
      })
  }, 5000)
  let [mobileMenu, setMobileMenu] = useState(false);
  //let [mobileMenuButton, setMobileMenuButton] = useState(false);
  //console.log( sessionStorage.getItem('chatActive') );
  if( !sessionStorage.getItem('chatActive') ){
    sessionStorage.setItem('chatActive', 'false')
  }
  if( !localStorage.getItem('currentView') ){
    setMenuItem = 'main'
    localStorage.setItem('currentView', 'all-posts')
  }
  if( sessionStorage.getItem('userloggedIn') === 'true' ){
    if( localStorage.getItem('allBaseData') === 'true' ){
      return(
        <div>
          <div className="u-container">
            <nav className="navbar-desktop mt-3 d-none d-md-block">
                <div className="u-container">
                    <div className="u-row">
                        <div className="u-col-6">
                            <img src={logo} className="logo" alt="Feelio" />
                        </div>
                        <div className="u-col-6 text-right">
                            <ul className="socials">
                                <li>
                                    <a href="https://www.facebook.com/Feelio.portal" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCqJMF2d7xqadu9VIcoHtJUQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/feelio.pl/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="u-row">
              <div className="u-col-12 d-none d-md-block">
              </div>
              <div className="u-col-12 d-md-none">
                <div className="mobile-header">
                  <div className="header">
                    <button className={(mobileMenu)?'d-none':'toogle-menu'} onClick={()=>setMobileMenu(true)}><span className="af-menu"></span></button>
                    <button className={(mobileMenu)?'toogle-menu':'d-none'} onClick={()=>setMobileMenu(false)}><span className="af-close"></span></button>
                    <img className="mobile-logo" src={logo} alt="Feelio"/>
                  </div>
                  <div className={(mobileMenu)?"navbar navbar-mobile":'d-none'}>
                    <div className="user_data text-center">
                      <div className="user_avatar">
                        <img src={user_avatar} alt={sessionStorage.getItem('userName')}/>
                      </div>
                      <div className="user_name text-center">
                        {sessionStorage.getItem('userName')}
                      </div>
                    </div>
                    <ul className={(menuItem === 'main')?'has-children active':'has-children'}>
                      <span role="button" onClick={()=>setMenuItem('main')} data-item="main"><span className="icon af-component-3"></span>Główna</span>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'all-posts' )?'active':null} onClick={showAllPosts}>Pokaż wszystkie posty</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'my-posts' )?'active':null} onClick={showMyPosts}>Zobacz swoje posty</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'create-posts' )?'active':null} onClick={showCreatePosts}>Stwórz nowy post</button>
                      </li>
                    </ul>
                    <ul className={(menuItem === 'profile')?'has-children active':'has-children'}>
                      <span role="button" onClick={()=>setMenuItem('profile')} data-item="profile" className={(menuItem === 'profile')?'active':null}><span className="icon af-profile"></span>Twój Profil</span>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'my-profile' )?'active':null} onClick={showMyProfile}>Zobacz swój profil</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'edit-profile' )?'active':null} onClick={showEditProfile}>Edytuj profil</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'my-gallery' )?'active':null} onClick={showMyGallery}>Twoja galeria</button>
                      </li>
                      <li>
                        <span>Ilość odwiedzin profilu <samp>{user_visit}</samp></span>
                      </li>
                      <li className="premium">
                        <button className={( localStorage.getItem('currentView') === 'visit' )?'active':null} onClick={showVisit}>Kto mnie odwiedził?</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'call-in' )?'active':null} onClick={showCallIn}>Otrzymane zaproszenia</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'call-out' )?'active':null} onClick={showCallOut}>Wysłane zaproszenia</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'my-friends' )?'active':null} onClick={showMyFriends}>Lista znajomych</button>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <button onClick={showNewFriends}><span className="icon af-search"></span>Poznaj nowe osoby</button>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <button onClick={showChat}><span className="icon af-messages"></span>Chat<span className={(have_chat_notyfi)?"have_notyfi show":"have_notyfi"}></span></button>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <button onClick={showNotyfications}><span className="icon af-bell"></span>Powiadomienia<span className={(have_notyfi)?"have_notyfi show":"have_notyfi"}></span></button>
                      </li>
                    </ul>
                    <ul className={(menuItem === 'premium')?'has-children active':'has-children'}>
                      <span role="button" onClick={()=>setMenuItem('premium')}><span className="icon af-star"></span>Premium</span>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'pro-subscribe' )?'active':null} onClick={showProSubscribe}>Subskrypcja</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'pro-settings' )?'active':null} onClick={showProSettings}>Ustawienia</button>
                      </li>
                    </ul>
                    <ul className={(menuItem === 'settings')?'has-children active':'has-children'}>
                      <span role="button" onClick={()=>setMenuItem('settings')}><span className="icon af-cog"></span>Ustawienia</span>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'report-user' )?'active':null} onClick={showReportUser}>Zgłoś użytkownika</button>
                      </li>
                      <li>
                        <button className={( localStorage.getItem('currentView') === 'report-bug' )?'active':null} onClick={showReportBug}>Zgłoś usterkę</button>
                      </li>
                    </ul>
                    <ul className={(menuItem === 'info')?'has-children active':'has-children'}>
                      <span role="button" onClick={()=>setMenuItem('info')}><span className="icon af-help"></span>Informacje</span>
                      <li>
                        <a href="https://feelio.pl/polityka-prywatnosci/" target="_blank" rel="noreferrer">Polityka prywatności</a>
                      </li>
                      <li>
                        <a href="https://feelio.pl/regulamin" target="_blank" rel="noreferrer">Regulamin</a>
                      </li>
                      <li>
                        <a href="https://feelio.pl/platnosci-i-reklamacje" target="_blank" rel="noreferrer">Płatności i reklamacje</a>
                      </li>
                      <li>
                        <a href="https://feelio.pl/kontakt/" target="_blank" rel="noreferrer">Kontakt</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <button onClick={userLogOut}><span className="icon af-heart"></span>Wyloguj</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="u-col-md-3 d-none d-md-block navbar">
                <div className="user_data text-center">
                  <div className="user_avatar">
                    <img src={user_avatar} alt={sessionStorage.getItem('userName')}/>
                  </div>
                  <div className="user_name text-center">
                    {sessionStorage.getItem('userName')}
                  </div>
                </div>
                <ul className={(menuItem === 'main')?'has-children active':'has-children'}>
                  <span role="button" onClick={()=>setMenuItem('main')} data-item="main"><span className="icon af-component-3"></span>Główna</span>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'all-posts' )?'active':null} onClick={showAllPosts}>Pokaż wszystkie posty</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'my-posts' )?'active':null} onClick={showMyPosts}>Zobacz swoje posty</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'create-posts' )?'active':null} onClick={showCreatePosts}>Stwórz nowy post</button>
                  </li>
                </ul>
                <ul className={(menuItem === 'profile')?'has-children active':'has-children'}>
                  <span role="button" onClick={()=>setMenuItem('profile')} data-item="profile" className={(menuItem === 'profile')?'active':null}><span className="icon af-profile"></span>Twój Profil</span>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'my-profile' )?'active':null} onClick={showMyProfile}>Zobacz swój profil</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'edit-profile' )?'active':null} onClick={showEditProfile}>Edytuj profil</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'my-gallery' )?'active':null} onClick={showMyGallery}>Twoja galeria</button>
                  </li>
                  <li>
                    <span>Ilość odwiedzin profilu <samp>{user_visit}</samp></span>
                  </li>
                  <li className="premium">
                    <button className={( localStorage.getItem('currentView') === 'visit' )?'active':null} onClick={showVisit}>Kto mnie odwiedził?</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'call-in' )?'active':null} onClick={showCallIn}>Otrzymane zaproszenia</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'call-out' )?'active':null} onClick={showCallOut}>Wysłane zaproszenia</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'my-friends' )?'active':null} onClick={showMyFriends}>Lista znajomych</button>
                  </li>
                </ul>
                <ul>
                  <li>
                    <button onClick={showNewFriends}><span className="icon af-search"></span>Poznaj nowe osoby</button>
                  </li>
                </ul>
                <ul>
                  <li>
                    <button onClick={showChat}><span className="icon af-messages"></span>Chat<span className={(have_chat_notyfi)?"have_notyfi show":"have_notyfi"}></span></button>
                  </li>
                </ul>
                <ul>
                  <li>
                    <button onClick={showNotyfications}><span className="icon af-bell"></span>Powiadomienia<span className={(have_notyfi)?"have_notyfi show":"have_notyfi"}></span></button>
                  </li>
                </ul>
                <ul className={(menuItem === 'premium')?'has-children active':'has-children'}>
                  <span role="button" onClick={()=>setMenuItem('premium')}><span className="icon af-star"></span>Premium</span>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'pro-subscribe' )?'active':null} onClick={showProSubscribe}>Subskrypcja</button>
                  </li>
                </ul>
                <ul className={(menuItem === 'settings')?'has-children active':'has-children'}>
                  <span role="button" onClick={()=>setMenuItem('settings')}><span className="icon af-cog"></span>Ustawienia</span>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'report-user' )?'active':null} onClick={showReportUser}>Zgłoś użytkownika</button>
                  </li>
                  <li>
                    <button className={( localStorage.getItem('currentView') === 'report-bug' )?'active':null} onClick={showReportBug}>Zgłoś usterkę</button>
                  </li>
                </ul>
                <ul className={(menuItem === 'info')?'has-children active':'has-children'}>
                  <span role="button" onClick={()=>setMenuItem('info')}><span className="icon af-help"></span>Informacje</span>
                  <li>
                    <a href="https://feelio.pl/polityka-prywatnosci/" target="_blank" rel="noreferrer">Polityka prywatności</a>
                  </li>
                  <li>
                    <a href="https://feelio.pl/regulamin" target="_blank" rel="noreferrer">Regulamin</a>
                  </li>
                  <li>
                    <a href="https://feelio.pl/platnosci-i-reklamacje" target="_blank" rel="noreferrer">Płatności i reklamacje</a>
                  </li>
                  <li>
                    <a href="https://feelio.pl/kontakt/" target="_blank" rel="noreferrer">Kontakt</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <button onClick={userLogOut}><span className="icon af-heart"></span>Wyloguj</button>
                  </li>
                </ul>
              </div>
              <div className="u-col-12 u-col-md-9 py-5">
                {showGoWinBack()}
                <AppView />
              </div>
              <div className="u-col-12 d-md-none bottom_tools">
                <ul>
                  <li>
                    <button onClick={showNewFriends}><span className="icon af-search"></span></button>
                  </li>
                  <li>
                    <button onClick={showAllPosts}><span className="icon af-component-3"></span></button>
                  </li>
                  <li>
                    <button onClick={showChat}><span className="icon af-messages"></span></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <footer className="main-footer">
                <div className="u-container">
                <div className="u-row">
                    <div className="u-col-12 u-col-md-2 text-center text-md-left align-self-end">
                        <a href="https://feelio.pl/">
                            <img src={logo_light} alt="" />
                        </a>
                    </div>
                    <div className="u-col-12 u-col-md-2 text-center">
                    <ul className="nav">
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/">
                            Główna
                        </a>
                        </li>
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/o-nas">
                            O Nas
                        </a>
                        </li>
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/o-aplikacji-feelio/">
                            Aplikacja Feelio
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div className="u-col-12 u-col-md-4 text-center">
                    <ul className="nav">
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/polityka-prywatnosci/">
                            Polityka prywatności
                        </a>
                        </li>
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/regulamin">
                            Regulamin
                        </a>
                        </li>
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/platnosci-i-reklamacje">
                            Płatności i reklamacje
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div className="u-col-12 u-col-md-2 text-center">
                    <ul className="nav">
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/kontakt/">
                            Kontakt
                        </a>
                        </li>
                        <li className="page_item menu-item">
                        <a href="https://feelio.pl/blog/">
                            Blog
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div className="u-col-12 u-col-md-2 text-center text-md-right">
                    <div className="social-links">
                        <a href="https://www.facebook.com/Feelio.portal" target="_blank" rel="noreferrer">
                        <i className="fe-facebook"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCqJMF2d7xqadu9VIcoHtJUQ" target="_blank" rel="noreferrer">
                        <i className="fe-youtube"></i>
                        </a>
                        <a href="https://www.instagram.com/feelio.pl/" target="_blank" rel="noreferrer">
                        <i className="fe-instagram"></i>
                        </a>
                    </div>
                    </div>
                    <div className="u-col-12 u-col-md-8 mx-auto text-center text-small py-4">
                    <p>W celu zapewnienia maksymalnej wygody użytkowników przy korzystaniu z witryny ta strona stosuje pliki cookies. Szczegóły w naszej <a href="https://feelio.pl/polityka-cookies/">Polityce cookies</a>.</p>
                    <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                    </div>
                </div>
                </div>
            </footer>
        </div>
      )
    }else{
      return(
        <>
          <nav className="navbar-desktop mt-3 d-none d-md-block">
            <div className="u-container">
              <div className="u-row">
                <div className="u-col-6">
                  <img src={logo} className="logo" alt="Feelio" />
                </div>
                <div className="u-col-6 text-right">
                  <ul className="socials">
                    <li>
                      <a href="https://www.facebook.com/Feelio.portal" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCqJMF2d7xqadu9VIcoHtJUQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/feelio.pl/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <Onbording />
        </>
      )
    }
  }else{
    return (
      <>
      <nav className="navbar-desktop mt-3 d-none d-md-block">
          <div className="u-container">
            <div className="u-row">
              <div className="u-col-6">
                <img src={logo} className="logo" alt="Feelio" />
              </div>
              <div className="u-col-6 text-right">
                <ul className="socials">
                  <li>
                    <a href="https://www.facebook.com/Feelio.portal" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCqJMF2d7xqadu9VIcoHtJUQ" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/feelio.pl/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      <LogIn />
      </>
    );
  }
}

export default App;
