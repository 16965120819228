function Step9(props)
{
if( props.currentStep !== 9 ){
    return null
}
    return(
        <div>
            <h3 className="header">Twoje zainteresowania</h3>
            <p className="lead">Określ swoje pasje aby inni mogli poznać Cię lepiej!</p>
            <div className="badge_checkbox">
                <div className="check">
                    <input type="checkbox" id="kulinaria" name="user_interest" value="kulinaria" checked={props.user_interest.find(element=> element === "kulinaria")} onChange={props.handleChange} />
                    <label htmlFor="kulinaria">Kulinaria</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="motoryzacja" name="user_interest" value="motoryzacja" checked={props.user_interest.find(element=> element === "motoryzacja")} onChange={props.handleChange} />
                    <label htmlFor="motoryzacja">Motoryzacja</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="taniec" name="user_interest" value="taniec" checked={props.user_interest.find(element=> element === "taniec")} onChange={props.handleChange} />
                    <label htmlFor="taniec">Taniec</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="bieganie" name="user_interest" value="bieganie" checked={props.user_interest.find(element=> element === "bieganie")} onChange={props.handleChange} />
                    <label htmlFor="bieganie">Bieganie</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="sport" name="user_interest" value="sport" checked={props.user_interest.find(element=> element === "sport")} onChange={props.handleChange} />
                    <label htmlFor="sport">Sport</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="sztuka" name="user_interest" value="sztuka" checked={props.user_interest.find(element=> element === "sztuka")} onChange={props.handleChange} />
                    <label htmlFor="sztuka">Sztuka</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="muzyka" name="user_interest" value="muzyka" checked={props.user_interest.find(element=> element === "muzyka")} onChange={props.handleChange} />
                    <label htmlFor="muzyka">Muzyka</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="ogrod" name="user_interest" value="ogrod" checked={props.user_interest.find(element=> element === "ogrod")} onChange={props.handleChange} />
                    <label htmlFor="ogrod">Ogród</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="filmy" name="user_interest" value="filmy" checked={props.user_interest.find(element=> element === "filmy")} onChange={props.handleChange} />
                    <label htmlFor="filmy">Filmy i seriale</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="majsterkowanie" name="user_interest" value="majsterkowanie" checked={props.user_interest.find(element=> element === "majsterkowanie")} onChange={props.handleChange} />
                    <label htmlFor="majsterkowanie">Majsterkowanie</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="netflix" name="user_interest" value="netflix" checked={props.user_interest.find(element=> element === "netflix")} onChange={props.handleChange} />
                    <label htmlFor="netflix">Netflix</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="woda" name="user_interest" value="woda" checked={props.user_interest.find(element=> element === "woda")} onChange={props.handleChange} />
                    <label htmlFor="woda">Sporty wodne</label>
                </div>
                <div className="check">
                    <input type="checkbox" id="spiew" name="user_interest" value="spiew" checked={props.user_interest.find(element=> element === "spiew")} onChange={props.handleChange} />
                    <label htmlFor="spiew">Śpiew</label>
                </div>
            </div>
        </div>
    );
}

export default Step9;