import React, {Component} from "react";

export default class Loader extends Component{
    render(){
        return(
            <div className="app-loader">
                <span className="anim-icon"></span>
                <span className="text">Ładowanie&nbsp;</span>
            </div>
        )
    }
}