import Select from "react-select";

function Step16(props)
{
if( props.currentStep !== 16 ){
    return null
}
    return(
        <div>
            <h3 className="header">Czy masz dzieci?</h3>
            <p className="lead mb-5">Zaznacz poniżej jedną odpowiedź.</p>
            <Select  
                name="user_children" 
                options={[
                    {value:'tak',label:'Tak'},
                    {value:'nie',label:'Nie'},
                    {value:'chce',label:'Nie, ale chcę mieć'}
                ]} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_children}
            />
        </div>
    );
}

export default Step16;