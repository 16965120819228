import Select from "react-select";

export default function Step11(props) {
    if( props.currentStep !== 11 ){
        return null
    }

    const country = [
        {value:'AD', label:'Andora'},
        {value:'AE', label:'Zjednoczone Emiraty Arabskie łącznie z Abu Drabi, Dubai, Sharjah, Ajman, Umm al Qaiwain Ras al Khaimh i Fujairh '},
        {value:'AF', label:'Afganistan'},
        {value:'AG', label:'Antigua i Barbuda'},
        {value:'AI', label:'Anguilla'},
        {value:'AL', label:'Albania'},
        {value:'AM', label:'Armenia'},
        {value:'AO', label:'Angola łącznie z Kabinda'},
        {value:'AQ', label:'Antarktyda'},
        {value:'AR', label:'Argentyna'},
        {value:'AS', label:'Samoa Amerykańskie'},
        {value:'AT', label:'Austria'},
        {value:'AU', label:'Australia'},
        {value:'AW', label:'Aruba'},
        {value:'AZ', label:'Azerbejdżan'},
        {value:'BA', label:'Bośnia i Hercegowina'},
        {value:'BB', label:'Barbados'},
        {value:'BD', label:'Bangladesz'},
        {value:'BE', label:'Belgia'},
        {value:'BF', label:'Burkina Faso'},
        {value:'BG', label:'Bułgaria'},
        {value:'BH', label:'Bahrajn'},
        {value:'BI', label:'Burundi'},
        {value:'BJ', label:'Benin'},
        {value:'BM', label:'Bermudy'},
        {value:'BN', label:'Brunei Darussalam'},
        {value:'BO', label:'Boliwia'},
        {value:'BQ', label:'Bonaire, St Eustatius i Saba'},
        {value:'BR', label:'Brazylia'},
        {value:'BS', label:'Bahamy'},
        {value:'BT', label:'Bhutan'},
        {value:'BV', label:'Wyspa Bouveta'},
        {value:'BW', label:'Botswana'},
        {value:'BY', label:'Białoruś'},
        {value:'BZ', label:'Belize'},
        {value:'CA', label:'Kanada'},
        {value:'CC', label:'Wyspy Kokosowe (Keelinga)'},
        {value:'CD', label:'Kongo, Republika Demokratyczna'},
        {value:'CF', label:'Republika Środkowej Afryki'},
        {value:'CG', label:'Kongo'},
        {value:'CH', label:'Szwajcaria'},
        {value:'CI', label:'Wybrzeże Kości Słoniowej'},
        {value:'CK', label:'Wyspy Cooka'},
        {value:'CL', label:'Chile'},
        {value:'CM', label:'Kamerun'},
        {value:'CN', label:'Chiny'},
        {value:'CO', label:'Kolumbia'},
        {value:'CR', label:'Kostaryka'},
        {value:'CU', label:'Kuba'},
        {value:'CV', label:'Wyspy Zielonego Przylądka'},
        {value:'CW', label:'Curacao'},
        {value:'CX', label:'Wyspa Bożego Narodzenia'},
        {value:'CY', label:'Cypr'},
        {value:'CZ', label:'Republika Czeska'},
        {value:'DE', label:'Niemcy łącznie z wyspą Helgoland'},
        {value:'DJ', label:'Dżibuti'},
        {value:'DK', label:'Dania'},
        {value:'DM', label:'Dominika'},
        {value:'DO', label:'Republika Dominikany'},
        {value:'DZ', label:'Algieria'},
        {value:'EC', label:'Ekwador łącznie z wyspami Galapagos'},
        {value:'EE', label:'Estonia'},
        {value:'EG', label:'Egipt'},
        {value:'ER', label:'Erytrea'},
        {value:'ES', label:'Hiszpania łącznie z wyspami Baleary, Kanaryjskie oraz miastami Ceuta i Melilla w Afryce Pónocnej'},
        {value:'ET', label:'Etiopia'},
        {value:'FI', label:'Finlandia łącznie z wyspami Alandzkimi '},
        {value:'FJ', label:'Republika Fidżi'},
        {value:'FK', label:'Wyspy Falklandy (Malwiny)'},
        {value:'FM', label:'Federacja Mikronezji'},
        {value:'FO', label:'Wyspy Owcze'},
        {value:'FR', label:'Francja łącznie z Monako, Gujaną Francuską, Martyniką, Gwadelupą i Reunion, Saint Barthelemy, Saint Martin, Mayotte oraz Saint Pierre i Miquelon'},
        {value:'GA', label:'Gabon'},
        {value:'GB', label:'Wielka Brytania'},
        {value:'GD', label:'Grenada'},
        {value:'GE', label:'Gruzja'},
        {value:'GG', label:'Guernsey'},
        {value:'GH', label:'Ghana'},
        {value:'GI', label:'Gibraltar'},
        {value:'GL', label:'Grenlandia'},
        {value:'GM', label:'Gambia'},
        {value:'GN', label:'Gwinea'},
        {value:'GQ', label:'Gwinea Równikowa'},
        {value:'GR', label:'Grecja'},
        {value:'GS', label:'Georgia Poóudniowa i Sandwich Południowy'},
        {value:'GT', label:'Gwatemala'},
        {value:'GU', label:'Guam'},
        {value:'GW', label:'Gwinea Bissau'},
        {value:'GY', label:'Gujana'},
        {value:'HK', label:'Hongkong'},
        {value:'HM', label:'Wyspa Heard i Wyspy McDonald'},
        {value:'HN', label:'Honduras'},
        {value:'HR', label:'Chorwacja'},
        {value:'HT', label:'Haiti'},
        {value:'HU', label:'Węgry'},
        {value:'ID', label:'Indonezja'},
        {value:'IE', label:'Irlandia'},
        {value:'IL', label:'Izrael'},
        {value:'IM', label:'Wyspa Man'},
        {value:'IN', label:'India'},
        {value:'IO', label:'Brytyjskie Terytorium Oceanu Indyjskiego łącznie z wyspami Czagos'},
        {value:'IQ', label:'Irak'},
        {value:'IR', label:'Iran'},
        {value:'IS', label:'Islandia'},
        {value:'IT', label:'Włochy'},
        {value:'JE', label:'Jersey '},
        {value:'JM', label:'Jamajka'},
        {value:'JO', label:'Jordania'},
        {value:'JP', label:'Japonia'},
        {value:'KE', label:'Kenia'},
        {value:'KG', label:'Kirgistan'},
        {value:'KH', label:'Kambodża (Kampucza)'},
        {value:'KI', label:'Kiribati'},
        {value:'KM', label:'Komory'},
        {value:'KN', label:'Saint Kitts i Nevis'},
        {value:'KP', label:'Koreańska Republika Ludowo-Demokratyczna (Korea Pónocna)'},
        {value:'KR', label:'Republika Korei (Korea Południowa)'},
        {value:'KW', label:'Kuwejt'},
        {value:'KY', label:'Kajmany'},
        {value:'KZ', label:'Kazachstan'},
        {value:'LA', label:'Laos'},
        {value:'LB', label:'Liban'},
        {value:'LC', label:'Saint Lucia'},
        {value:'LI', label:'Lichtenstein'},
        {value:'LK', label:'Sri Lanka'},
        {value:'LR', label:'Liberia'},
        {value:'LS', label:'Lesotho'},
        {value:'LT', label:'Litwa'},
        {value:'LU', label:'Luksemburg'},
        {value:'LV', label:'Łotwa'},
        {value:'LY', label:'Libia'},
        {value:'MA', label:'Maroko łącznie Saharą Zachodnią'},
        {value:'MD', label:'Mołdowa'},
        {value:'ME', label:'Czarnogóra'},
        {value:'MG', label:'Madagaskar'},
        {value:'MH', label:'Wyspy Marshalla'},
        {value:'MK', label:'Macedonia'},
        {value:'ML', label:'Mali'},
        {value:'MM', label:'Myanmar (Birma)'},
        {value:'MN', label:'Mongolia'},
        {value:'MO', label:'Makao'},
        {value:'MP', label:'Mariany Północne'},
        {value:'MR', label:'Mauretania'},
        {value:'MS', label:'Montserrat'},
        {value:'MT', label:'Malta'},
        {value:'MU', label:'Mauritius'},
        {value:'MV', label:'Malediwy'},
        {value:'MW', label:'Malawi'},
        {value:'MX', label:'Meksyk'},
        {value:'MY', label:'Malezja'},
        {value:'MZ', label:'Mozambik'},
        {value:'NA', label:'Namibia'},
        {value:'NC', label:'Nowa Kaledonia'},
        {value:'NE', label:'Niger'},
        {value:'NF', label:'Wyspa Norfolk'},
        {value:'NG', label:'Nigeria'},
        {value:'NI', label:'Nikaragua'},
        {value:'NL', label:'Niderlandy'},
        {value:'NO', label:'Norwegia łącznie z wyspami Svalbard i Jan Mayen'},
        {value:'NP', label:'Nepal'},
        {value:'NR', label:'Nauru'},
        {value:'NU', label:'Niue'},
        {value:'NZ', label:'Nowa Zelandia'},
        {value:'OM', label:'Oman łącznie z wyspami Kuria Muria'},
        {value:'PA', label:'Panama'},
        {value:'PE', label:'Peru'},
        {value:'PF', label:'Polinezja Francuska'},
        {value:'PG', label:'Papua Nowa Gwinea'},
        {value:'PH', label:'Filipiny'},
        {value:'PK', label:'Pakistan'},
        {value:'PN', label:'Pitcairn'},
        {value:'PL', label:'Polska'},
        {value:'PS', label:'Palestyna'},
        {value:'PT', label:'Portugalia łącznie z wyspami Acores (Azory) i Madeira'},
        {value:'PW', label:'Palau'},
        {value:'PY', label:'Paragwaj'},
        {value:'QA', label:'Katar'},
        {value:'RO', label:'Rumunia'},
        {value:'RS', label:'Serbia'},
        {value:'RU', label:'Federacja Rosyjska'},
        {value:'RW', label:'Rwanda'},
        {value:'SA', label:'Arabia Saudyjska'},
        {value:'SB', label:'Wyspy Salomona'},
        {value:'SC', label:'Seszele'},
        {value:'SD', label:'Sudan'},
        {value:'SE', label:'Szwecja'},
        {value:'SG', label:'Singapur'},
        {value:'SH', label:'Wyspa Świętej Heleny'},
        {value:'SI', label:'Słowenia'},
        {value:'SK', label:'Słowacja'},
        {value:'SL', label:'Sierra Leone'},
        {value:'SM', label:'San Marino'},
        {value:'SN', label:'Senegal'},
        {value:'SO', label:'Somalia'},
        {value:'SR', label:'Surinam'},
        {value:'ST', label:'Wyspy Świętego Tomasza i Ksi���ca'},
        {value:'SV', label:'Salwador'},
        {value:'SX', label:'Sint Maarten'},
        {value:'SY', label:'Syria'},
        {value:'SZ', label:'Swaziland'},
        {value:'TC', label:'Wyspy Turks i Caicos'},
        {value:'TD', label:'Czad'},
        {value:'TF', label:'Francuskie Terytoria Południowe'},
        {value:'TG', label:'Togo'},
        {value:'TH', label:'Tajlandia'},
        {value:'TJ', label:'Tadżykistan'},
        {value:'TK', label:'Tokelau'},
        {value:'TL', label:'Timor Wschodni'},
        {value:'TM', label:'Turkmenistan'},
        {value:'TN', label:'Tunezja'},
        {value:'TO', label:'Tonga'},
        {value:'TR', label:'Turcja'},
        {value:'TT', label:'Trynidad i Tobago'},
        {value:'TV', label:'Tuvalu'},
        {value:'TW', label:'Tajwan'},
        {value:'TZ', label:'Tanzania łącznie z wyspami Tanganika, Zanzibar i Pemba'},
        {value:'UA', label:'Ukraina'},
        {value:'UG', label:'Uganda'},
        {value:'UM', label:'Terytoria Zamorskie Stanów Zjednoczonych'},
        {value:'US', label:'Stany Zjednoczone Ameryki Północnej łącznie z Portoryko i Navassa'},
        {value:'UY', label:'Urugwaj'},
        {value:'UZ', label:'Uzbekistan'},
        {value:'VA', label:'Watykan'},
        {value:'VC', label:'Saint Vincent i Grenadyny'},
        {value:'VE', label:'Wenezuela'},
        {value:'VG', label:'Brytyjskie Wyspy Dziewicze'},
        {value:'VI', label:'Wyspy Dziewicze Stanów Zjednoczonych'},
        {value:'VN', label:'Wietnam'},
        {value:'VU', label:'Vanuatu'},
        {value:'WF', label:'Wallis i Futuna'},
        {value:'WS', label:'Samoa'},
        {value:'XK', label:'Kosowo'},
        {value:'YE', label:'Jemen łącznie z wyspami Perim, Kamaran, Socotra'},
        {value:'ZA', label:'Republika Południowej Afryki'},
        {value:'ZM', label:'Zambia'},
        {value:'ZW', label:'Zimbabwe'}
    ]

    return(
        <div>
            <h3 className="header">Narodowość</h3>
            <p className="lead">Wybierz z jakiego kraju jesteś.</p>
            <Select  
                name="user_country" 
                options={country} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_country}
            />
        </div>
    );
}