import Avatar from "./Avatar"
import Editor,{createEditorStateWithText} from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import {stateToHTML} from 'draft-js-export-html';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/emoji/lib/plugin.css';
import parse from "html-react-parser";
import config from '../config.json';
import React, { Component } from "react";
import UserTableRooms from "./UserTableRooms";
import axios from "axios";

const emojiPlugin = createEmojiPlugin();
const imagePlugin = createImagePlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const plugins = [emojiPlugin, imagePlugin];
const text = '';

export default class Room extends Component {
    chatContainer = React.createRef();
    state = {
        post_content:[],
        post_content_count: 0,
        post_data:'',
        content_is_image: false,
        content_image:'',
        showEmoji:false,
        editorState: createEditorStateWithText(text),
    }

    _handleImageChange(e) {
        e.preventDefault();
        //console.log('dodaję plik');
        this.setState({
            content_is_image:true
        })
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState({
            editorState: createEditorStateWithText('<img src="'+reader.result+'" alt="">'),
            content_image: '<img src="'+reader.result+'" alt="">'
          });
          //console.log( JSON.stringify( '<img src="'+reader.result+'" alt="">' ) )
        }
    
        reader.readAsDataURL(file)
    }

    onChange = (editorState) => {
        this.setState({
            editorState
        })
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    scrollToMyRef = () => {
        const chatContainer = document.getElementsByClassName('chet_messages')[0];
        const chatList = chatContainer.getElementsByTagName('ul')[0];
        console.log( chatList.getElementsByClassName('message_ender')[0].offsetTop )
        chatList.scrollTo(0,chatList.getElementsByClassName('message_ender')[0].offsetTop)
    };

    componentDidMount(){
        let d = new Date();
        let t = d.getTime();
        //console.log('ostatnia aktywność: '+t)
        //console.log( JSON.stringify('<div><img src="https://google.pl"><div>') )
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/room/${localStorage.getItem('currentRoom')}`)
            .then(res=>{
                //console.log(JSON.parse(res.data[0].content).length)
                this.setState({
                    post_content_count: JSON.parse(res.data[0].content).length
                })
                if( parseInt(res.data[0].delivery) === parseInt(sessionStorage.getItem('userID')) ){
                    this.setState({
                        post_content:JSON.parse(res.data[0].content),
                        post_data: res.data[0].user
                    })
                }else{
                    this.setState({
                        post_content:JSON.parse(res.data[0].content),
                        post_data: res.data[0].delivery
                    })
                }
                this.scrollToMyRef()
            })
    }
    componentDidUpdate(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/room/${localStorage.getItem('currentRoom')}`)
            .then(res=>{
                //console.log( JSON.parse(res.data[0].content).length )
                //console.log( this.state.post_content_count )
                if( parseInt(res.data[0].delivery) === parseInt(sessionStorage.getItem('userID')) ){
                    this.setState({
                        post_content:JSON.parse(res.data[0].content),
                    })
                }else{
                    this.setState({
                        post_content:JSON.parse(res.data[0].content),
                    })
                }
                if( this.state.post_content_count !== JSON.parse(res.data[0].content).length ){
                    //console.log('przewiń')
                    this.scrollToMyRef()
                    this.setState({
                        post_content_count: JSON.parse(res.data[0].content).length
                    })
                }
            })
    }

    focus = () => {
        this.editor.focus()
    }

    handleBack = () =>{
        localStorage.setItem('currentView','chat');
        window.location.reload(true);
    }
    checkUsersChatActivity = () => {
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/room/${localStorage.getItem('currentRoom')}`)
            .then(res=>{
                if( parseInt(res.data[0].delivery) !== sessionStorage.getItem('userID') ){
                    axios
                        .get(`${config.dbURL}/wp-json/fac/v1/activity/${parseInt(res.data[0].delivery)}`)
                        .then(resp=>{
                            //console.log(resp)
                            //console.log(resp.data.user_activity)
                            if( resp.data.user_activity !== 'true'){
                                axios
                                    .post(`${config.dbURL}/wp-json/fac/v1/notification/${parseInt(res.data[0].delivery)}`,{
                                    content: 'Masz nową wiadomość.',
                                    type: 'chat'
                                    })
                            }
                        })
                }else if( parseInt(res.data[0].user) !== sessionStorage.getItem('userID') ){
                    axios
                        .get(`${config.dbURL}/wp-json/fac/v1/activity/${parseInt(res.data[0].user)}`)
                        .then(resp=>{
                            //console.log(resp)
                            //console.log(resp.data.user_activity)
                            if( resp.data.user_activity !== 'true'){
                                axios
                                    .post(`${config.dbURL}/wp-json/fac/v1/notification/${parseInt(res.data[0].user)}`,{
                                    content: 'Masz nową wiadomość.',
                                    type: 'chat'
                                    })
                            }
                        })
                }
            })
    }
    addMessage = () =>{
        let d = new Date();
        let t = d.getTime();
        let content = this.state.post_content
        let options = {
            defaultBlockTag: 'div',
            entityStyleFn: (entity) => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'image') {
                  const data = entity.getData();
                  return {
                    element: 'img',
                    attributes: {
                      src: data.src,
                    },
                  };
                }
              },
        };
        let newData
        if( this.state.content_is_image ){
            newData = {
                id: sessionStorage.getItem('userID'),
                message: this.state.content_image
            }
        }else{
            newData = {
                id: sessionStorage.getItem('userID'),
                message: stateToHTML(this.state.editorState.getCurrentContent(), options)
            }
        }
        //content.push(stateToHTML(this.state.editorState.getCurrentContent()));
        content.push(newData);
        this.setState({
            post_content:content,
            editorState: createEditorStateWithText(text)
        })
        //console.log(this.state.post_content)
        let newPost = {
            content:JSON.stringify(this.state.post_content),
            lastEdit: t
        }
        //console.log(this.state.post_content)
        //console.log(newPost)
        axios
            .post(`${config.dbURL}/wp-json/fac/v1/room/${localStorage.getItem('currentRoom')}`, (newPost))
            this.checkUsersChatActivity();
            /* axios
                                    .post(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`,{
                                    content: 'Masz nową wiadomość.',
                                    type: 'chat'
                                    }) */
        this.setState({
            content_is_image:false,
            content_image:'',
        })
        /* this.scrollToMyRef(); */
    }
    renderMessages = (data, index) =>{
        //console.log(data.message)
        if(data.id === sessionStorage.getItem('userID')){
            return(
                <li className="left_side" key={index}>
                    <Avatar user_id={data.id} />
                    <div className="message ml-3">
                        {parse(data.message)}
                    </div>
                </li>
            )
        }else{
            return(
                <li className="right_side" key={index}>
                    <div className="message ml-3">
                        {parse(data.message)}
                    </div>
                    <Avatar user_id={this.state.post_data} />
                </li>
            )
        }
    }
    render(){
        let {post_content,post_data, editorState} = this.state;
        //console.log(post_data)
        return(
            <div className="account_box px-5 py-4 char_room">
                <header>
                    <button className="go-back mb-3" onClick={this.handleBack}><span className="af-arrow-right icon-rotate-x"></span>&nbsp;Zamknij</button>
                    <div className="text-center">
                        <Avatar user_id={post_data} />
                    </div>
                </header>
                <div className="chet_messages" ref={this.chatContainer}>
                    <ul>
                        {post_content.map( (post, index) =>( this.renderMessages(post, index) ))}
                        <li className="message_ender"></li>
                    </ul>
                </div>
                <div className="message_sender">
                    <div className="textarea" onClick={this.focus}>
                    <Editor
                        editorState={editorState}
                        onChange={this.onChange}
                        plugins={plugins}
                        ref={(element) => {
                            this.editor = element;
                        }}
                    />
                    </div>
                    <button className="add_message" onClick={this.addMessage}>wyślij&nbsp;<span className="af-arrow-right"></span></button>
                </div>
                    <div className="previewComponent d-inline-block">
                        <label className="insertPostImage">
                            <span className="af-camera"></span>
                            <input className="fileInput" 
                                type="file" 
                                onChange={(e)=>this._handleImageChange(e)} />
                        </label>
                    </div>
                    <EmojiSelect />
                {/* Pokuj czatu rozmawia {sessionStorage.getItem('userID')} z {localStorage.getItem('currentRoom')} */}
            </div>
        )
    }
} 