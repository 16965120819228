import React, {Component} from "react";

export default class SmallLoader extends Component{
    render(){
        return(
            <div className="small-loader">
                <span className="text">{(this.props.content!=="")?this.props.content:"Ładowanie"}&nbsp;</span>
            </div>
        )
    }
}