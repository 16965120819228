function Step5(props)
{
if( props.currentStep !== 5 ){
    return null
}
    return(
        <div>
            <h3 className="header">Jesteś...</h3>
            <p className="lead">Wybierz swoją płeć.</p>
            <div className="custom_radio radio_inline my-4">
                <div className="custom-radio">
                    <div className="check">
                        <input type="radio" id="przyjazni" name="user_sex" value="kobieta" checked={props.user_sex === "kobieta"} onChange={props.handleChange} />
                        <label htmlFor="przyjazni">Kobietą</label>
                    </div>
                    <div className="check">
                        <input type="radio" id="milosci" name="user_sex" value="mezczyzna" checked={props.user_sex === "mezczyzna"} onChange={props.handleChange} />
                        <label htmlFor="milosci">Mężczyzną</label>
                    </div>
                    <div className="check">
                        <input type="radio" id="znajomosci" name="user_sex" value="inne" checked={props.user_sex === "inne"} onChange={props.handleChange} />
                        <label htmlFor="znajomosci">Inne</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step5;