import React, {Component} from 'react';
//import avatar from '../default_user.png';
import Avatar from 'react-avatar-edit';
import axios from "axios";
import config from '../../config.json'

//const apiAuth = window.wpApiSettings;

class Step6 extends Component
{
    constructor(props){
        super(props)
        this.state = {
            preview: ''
        }
    }
    
    onClose() {
        this.setState({preview: ''})
    }
    onCrop = (preview) => {
        this.setState({preview})
        axios
          .post(`${config.dbURL}/wp-json/fac/v1/users/${sessionStorage.getItem('userID')}`, {user_avatar:preview})
    }
    onBeforeFileLoad(elem){
        if( elem.target.files[0].size > 40000000 ){
            alert("File is too big!");
            elem.target.value = "";
        }
    }
    render(){
        if( this.props.currentStep !== 6 ){
            return null
        }
        return(
            <div>
                <h3 className="header">Twój avatar</h3>
                <p className="lead">Dodaj swoje zdjęcie profilowe, które będą widzieć inni użytkownicy!</p>
                <div className="custom_file file_avatar mt-4 mb-5">
                    <label>
                        <Avatar
                            width={170}
                            height={170}
                            onCrop={this.onCrop}
                            //onClose={onClose}
                            label="+"
                            onBeforeFileLoad={this.onBeforeFileLoad}
                            src={this.props.user_avatar}
                            name="user_avatar"
                        />
                    </label>
                </div>
                <p className="lead">Zdjęcie nie może mieć więcej niż 5MB.</p>
            </div>
        );
    }
}

export default Step6;