import { Component } from "react";
import Tabs, { TabPane } from 'rc-tabs';
import userImage from '../asset/images/default_user.png';
import bg_1 from '../asset/images/chat_rooms.svg';
import bg_2 from '../asset/images/chat_people.svg';
import axios from "axios";
import Avatar from "./Avatar";
import RoomAvatar from "./RoomAvatar";
import { isRestElement } from "@babel/types";
import Alerts from "./alerts";
import config from '../config.json'

export default class Chat extends Component{

    constructor()
    {
        super()
        this.state = {
            search: '',
            user_image:'',
            user_name:'',
            users: [],
            rooms:[],
            errors:[],
            content:[],
            counter:0
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users`)
            .then(res => {
                this.setState({
                    users: res.data
                })
            })
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/rooms/${sessionStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' ){
                    this.setState({
                        errors:['info', 'Nie rozpocząłeś jeszcze żadnej konwersacji.']
                    })
                }else{
                    this.setState({
                        rooms: res.data
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === undefined ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                this.setState({
                    errors:['warning']
                })
            })
    }

    createToRoom = e =>{
        e.preventDefault();
        let room_who, room_with;
        room_who = e.target.dataset.who;
        room_with = e.target.dataset.with;
        let roomData = {
            delivery: e.target.dataset.with,
            content: JSON.stringify(this.state.content)
        }
        axios.post(`${config.dbURL}/wp-json/fac/v1/rooms/${sessionStorage.getItem('userID')}`, roomData)
        .then(res=>{
            localStorage.setItem('currentView', 'room');
            console.log(res)
            window.location.reload(true);
        });

    }
    goToRoom = e =>{
        e.preventDefault();
        let room_id =e.target.dataset.room;
        axios.get(`${config.dbURL}/wp-json/fac/v1/room/${room_id}`)
        .then(res=>{
            localStorage.setItem('currentView', 'room');
            localStorage.setItem('currentRoom', room_id);
    
            window.location.reload(true);
        });

    }

    renderUsersList(users, index){
        //console.log(users)
        let image = (users.user_avatar === undefined || users.user_avatar[0] === "") ? userImage:users.user_avatar;
        return(
            <div className="u-col-12 px-3 room_user_list" key={index}>
                <a href="#" onClick={this.createToRoom} data-who={sessionStorage.getItem('userID')} data-with={users.id}>
                    <div className="avatar">
                        <div className="image">
                            <img src={image} alt={users.user_nick} />
                        </div>
                        <div className="name">
                            <h5 className="user_name">{users.user_nick}</h5>
                        </div>
                    </div>
                </a>
            </div>
        );

    }
    renderRoomsAvatar(user_id, counter, user_side){
        return <RoomAvatar user_id={user_id} counter={counter} user_side={user_side}/>
        
    }
    renderUsersRooms(rooms, index){
        let user_image;
        let user_name;
        let counter;
        //console.log(rooms)
        if(rooms.user === sessionStorage.getItem('userID')){
            //console.log('mam avatar')
            return(
                <>
                    <div className="u-col-12 px-3 room_user_list" key={index}>
                        <a href="#" onClick={this.goToRoom} data-room={rooms.id}>
                            {this.renderRoomsAvatar(rooms.delivery, rooms.id, 'user')}
                        </a>
                    </div>
                </>
            );
        }else{
            return(
                <>
                    <div className="u-col-12 px-3 room_user_list" key={index}>
                        <a href="#" onClick={this.goToRoom} data-room={rooms.id}>
                            {this.renderRoomsAvatar(rooms.user, rooms.id, 'delivery')}
                        </a>
                    </div>
                </>
            );
        }

        
    }
    onchange = e => {
        this.setState({ search: e.target.value });
    };
    render(){
        const { search } = this.state;
        const filteredUsers = this.state.users.filter(users => {
            return users.user_nick.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        });
        //console.log(this.state.rooms)
        return(
            <div className="account_box px-5 pt-3 chat_lists">
                <Tabs>
                    <TabPane tab="Czaty" key="1">
                        <div className="u-row mb-5">
                            <div className="u-col-10 u-col-md-6 mx-auto text-center">
                                <input type="text" placeholder="Wpisz nick" onChange={this.onchange}/>
                            </div>
                            {(this.state.errors.length > 0)?
                                <div className="u-col-12 px-3">
                                    <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                                </div>
                            :
                                this.state.rooms.map( (users, index) => this.renderUsersRooms(users, index))
                            }
                            <div className="u-col-12 bg_bottom">
                                <img src={bg_1} alt="Tło" />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Osoby" key="2">
                        <div className="u-row mb-5">
                            <div className="u-col-10 u-col-md-6 mx-auto text-center">
                                <input type="text" placeholder="Wpisz nick" onChange={this.onchange}/>
                            </div>
                            {filteredUsers.map((users,index) => {return this.renderUsersList(users,index);})}
                            <div className="u-col-12 bg_bottom">
                                <img src={bg_2} alt="Tło" />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}