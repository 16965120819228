function Step3(props)
{
if( props.currentStep !== 3 ){
    return null
}
    return(
        <div>
            <h3 className="header">Twoja miejscowość...</h3>
            <p className="lead">Podaj skąd jesteś.</p>
            <div>
                <input type="text" name="user_city" value={props.user_city} onChange={props.handleChange}></input>
            </div>
        </div>
    );
}

export default Step3;