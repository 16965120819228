function Step13(props)
{
if( props.currentStep !== 13 ){
    return null
}
    return(
        <div>
            <h3 className="header">Wyznanie</h3>
            <p className="lead mb-5">Określ swoje wyznanie.</p>
            <input type="text" name="user_religion" value={props.user_religion}  onChange={props.handleChange}/>
        </div>
    );
}

export default Step13;