import { Component } from "react";

export default class Subscribe extends Component{

    render(){
        return(
            <div className="u-row">
                <div className="u-col-8 mx-auto text-center">
                    <h3>Dołącz do społeczności Feelio i ciesz się darmową rejestracją, a konto Premium złap za jedyne 29,99 pln!</h3>
                </div>
                <div className="u-col-12 u-col-md-4 offset-md-2">
                    <div className="price-card free">
                        <div className="name text-center">wariant podstawowy</div>
                        <div className="price text-center">00,00 pln</div>
                        <div className="desc text-center">msc</div>
                        <ul className="check-list">
                            <li>wyszukiwanie w określonej miejscowości</li>
                            <li>edycja promienia wyszukiwania</li>
                            <li>wyszukiwanie za pomocą nicku</li>
                            <li>opcja "kto odwiedził mój profil"</li>
                            <li>opcja komentowania postów</li>
                            <li>podpięcie galerii Instagram</li>
                        </ul>
                        <div className="action my-3">
                            {/* obecny wariant */}
                        </div>
                    </div>
                </div>
                <div className="u-col-12 u-col-md-4 offset-md-1">
                    <div className="price-card premium">
                        <div className="name text-center">wariant premium</div>
                        <div className="price text-center">29,99 pln</div>
                        <div className="desc text-center">msc</div>
                        <ul className="check-list disabled">
                            <li>wyszukiwanie w określonej miejscowości</li>
                            <li>edycja promienia wyszukiwania</li>
                            <li>wyszukiwanie za pomocą nicku</li>
                            <li>opcja "kto odwiedził mój profil"</li>
                            <li>opcja komentowania postów</li>
                            <li>podpięcie galerii Instagram</li>
                        </ul>
                        <div className="action">
                            {/* <a href="https://s.przelewy24.pl/C7ynq6vDT6SK" className="btn btn-default" target="_blank" rel="noreferrer">WYPRÓBUJ PREMIUM</a> */}
                            obecny wariant
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}