import Select from "react-select";

function Step10(props)
{
if( props.currentStep !== 10 ){
    return null
}
    return(
        <div>
            <h3 className="header">Edukacja</h3>
            <p className="lead">Wykształcenie to dobro, którego nic nie jest w stanie nas pozbawić.</p>
            <Select  
                name="user_education" 
                options={[
                    {value:'wyzsze',label:'Wyższe'},
                    {value:'srednie',label:'Średnie'},
                    {value:'podstawowe',label:'Podstawowe'}
                ]} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_education}
            />
        </div>
    );
}

export default Step10;