function Step18(props)
{
    if( props.currentStep !== 18 ){
        return null
    }
    return(
        <div>
            <h3 className="header">Czy masz nałogi?</h3>
            <p className="lead mb-5"></p>
                <div className="custom-checkbox">
                    <div className="check">
                        <input type="checkbox" id="narkotyki" name="user_addictions" value="narkotyki" checked={props.user_addictions.find(element=> element === "narkotyki")} onChange={props.handleChange} />
                        <label htmlFor="narkotyki">Narkotyki</label>
                    </div>                                    
                    <div className="check">
                        <input type="checkbox" id="alkohol" name="user_addictions" value="alkohol" checked={props.user_addictions.find(element=> element === "alkohol")} onChange={props.handleChange} />
                        <label htmlFor="alkohol">Alkohol</label>
                    </div>                                    
                    <div className="check">
                        <input type="checkbox" id="papierosy" name="user_addictions" value="papierosy" checked={props.user_addictions.find(element=> element === "papierosy")} onChange={props.handleChange} />
                        <label htmlFor="papierosy">Papierosy</label>
                    </div>                                    
                    <div className="check">
                        <input type="checkbox" id="addictions_inne" name="user_addictions" value="inne" checked={props.user_addictions.find(element=> element === "inne")} onChange={props.handleChange} />
                        <label htmlFor="addictions_inne">Inne</label>
                    </div>                                    
                    <div className="check">
                        <input type="checkbox" id="brak" name="user_addictions" value="brak" checked={props.user_addictions.find(element=> element === "brak")} onChange={props.handleChange} />
                        <label htmlFor="brak">Nie mam nałogów</label>
                    </div>                                    
                </div>
        </div>
    );
}

export default Step18;