import React, { Component } from "react";
import axios from "axios";
import Loader from './loader';
import PostItem from './PostItem';
import Alerts from "./alerts";
import config from '../config.json'

export default class PostList extends Component{

    state = {
        posts: [],
        isLoaded: false
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/post`)
            .then(res => this.setState({
                posts: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));
    }

    render(){
        const {posts, isLoaded} = this.state;
        console.log( posts )
        if( isLoaded ){
            if( posts.length > 0 ){
                return(
                    <div className="u-row">
                        {posts.map( (post, index) => (
                            <PostItem key={index} post={post} />
                        ) )}
                    </div>
                );
            }else{
                return(
                    <div className="u-row">
                        <div className="u-col-12">
                            <Alerts type='info' message='Brak wpisów.' />
                        </div>
                    </div>
                );
            }
        }else{
            return <Loader />
        }
    }
}