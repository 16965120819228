//import step4IMG from '../step4.png';

//const apiAuth = window.wpApiSettings;

function Step3(props)
{
if( props.currentStep !== 4 ){
    return null
}
    return(
        <div>
            {/* <img src={apiAuth.files+step4IMG} alt="Feelio - witaj" /> */}
            <h3 className="header mt-5">Cześć <br/>{props.user_nick}</h3>
            <p className="lead mt-3 mb-5">Zacznij uzupełniać swój profil! Pomożemy przygotować Ci super konto dzięki któremu poznasz ciekawych ludzi!</p>
        </div>
    );
}

export default Step3;