import { Component } from "react";
import PostsLists from './PostsList';
import MyPosts from './MyPosts';
import CreatePost from './CreatePost';
import MyAccount from './MyAccount';
import EditMyAccount from './EditMyAccount';
import MyGallery from './MyGallery';
import CallIn from './CallIn';
import CallOut from './Callout';
import Friends from "./Friends";
import NewFriends from './NewFriends';
import Notification from './Notyfications';
import ReportUser from './ReportUser';
import ReportBug from './ReportBug';
import Subscribe from './Subscribe';
import Settings from "./Settings";
import PostView from "./PostView";
import Chat from './Chat';
import Room from "./Room";
import UserView from "./UserView";
import Visit from "./Visit";
import axios from "axios";
import config from '../config.json';

export default class AppView extends Component{

    componentDidUpdate(){
        if(sessionStorage.getItem('chatActive') === "true"){
            axios
                .post(`${config.dbURL}/wp-json/fac/v1/activity/${sessionStorage.getItem('userID')}`, {
                    status: "true"
                })
        }else{
            axios
            .post(`${config.dbURL}/wp-json/fac/v1/activity/${sessionStorage.getItem('userID')}`, {
                status: "false"
            })
        }
    }
    
    render(){
        if( localStorage.getItem('currentView') === 'all-posts' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <PostsLists />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'my-posts' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <MyPosts />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'create-posts' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <CreatePost />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'my-profile' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <MyAccount />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'edit-profile' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <EditMyAccount />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'my-gallery' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <MyGallery />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'call-in' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <CallIn />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'call-out' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <CallOut />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'my-friends' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Friends />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'visit' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Visit />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'new-friends' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <NewFriends />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'chat' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Chat />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'notyfications' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Notification />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'pro-subscribe' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Subscribe />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'pro-settings' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <Settings />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'report-user' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <ReportUser />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'report-bug' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <ReportBug />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'post-view' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <PostView post_id={localStorage.getItem('currentPost')} />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'room' ){
            sessionStorage.setItem('chatActive', 'true')
            return(
                <>
                    <Room />
                </>
            )
        }
        if( localStorage.getItem('currentView') === 'user-profile' ){
            sessionStorage.setItem('chatActive', 'false')
            return(
                <>
                    <UserView user={localStorage.getItem('showUser')} />
                </>
            )
        }
    }
}