import { Component } from "react";
import axios from "axios";
import Alerts from "./alerts";
import config from '../config.json'

export default class Notyfications extends Component{

    state ={
        messages: [],
        errors:[]
    }

    groupBy = (array, key, status) => {
        return array.reduce((result, currentValue) => {
            if( currentValue.status === status ){
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            }
            return result
        },{})
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' || res.data.status === 'hidden' ){
                    this.setState({
                        errors:['info', 'Brak powiadomień.']
                    })
                }else{
                    const friData = res.data
                    //console.log( friData )
                    this.setState({
                        messages: this.groupBy( friData, 'date', "show"),
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
            })
    }
    componentDidUpdate(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`)
            .then(res => {
                if( res.data.code === 'no_data' || res.data.status === 'hidden' ){
                    this.setState({
                        errors:['info', 'Brak powiadomień.']
                    })
                }else{
                    const friData = res.data
                    //console.log( friData )
                    this.setState({
                        messages: this.groupBy( friData, 'date', "show"),
                    })
                }
            })
            .catch(err => {
                if( err.response.status === 400 ){
                    this.setState({
                        errors:['warning']
                    })
                }
                if( err.response.status === 401 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 404 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
                if( err.response.status === 500 ){
                    this.setState({
                        errors:['warning', err.response.data.message]
                    })
                }
            })
    }
    deleteMessage = e => {
        console.log('usuń wiadomość ID: '+e.target.dataset.id)
        axios.post(`${config.dbURL}/wp-json/fac/v1/notifications/${e.target.dataset.id}`,{}).then(res=>{window.location.reload(true)})
    }
    goToCallIn = e => {
        e.preventDefault()
        localStorage.setItem('currentView','call-in')
        window.location.reload(true)
    }
    goToFriends = e => {
        e.preventDefault()
        localStorage.setItem('currentView','my-friends')
        window.location.reload(true)
    }
    goToChat = e => {
        e.preventDefault()
        localStorage.setItem('currentView','chat')
        window.location.reload(true)
    }

    renderNotificationList = (info, key) => {
        if( info.status === 'delete' ){
            return null
        }
        if( info.type === 'newfriends' ){
            return(
                <li className="notification align-items-center" key={key}>
                    <div className="icon u-col-1">
                        <span className="af-profile"></span>
                    </div>
                    <div className="message u-col-10">
                        {info.content}<br/>
                        Sprawdź <a href="#" onClick={this.goToCallIn}>Listę zaproszeń</a>
                    </div>
                    <div className="delete u-col-1 text-right">
                        <button className="af-close" onClick={this.deleteMessage} data-id={info.id}></button>
                    </div>
                </li>
            )
        }
        if( info.type === 'acceptfriends' ){
            return(
                <li className="notification align-items-center" key={key}>
                    <div className="icon u-col-1">
                        <span className="af-profile"></span>
                    </div>
                    <div className="message u-col-10">
                        {info.content}<br/>
                        Sprawdź <a href="#" onClick={this.goToFriends}>Listę znajomych</a>
                    </div>
                    <div className="delete u-col-1 text-right">
                        <button className="af-close" onClick={this.deleteMessage} data-id={info.id}></button>
                    </div>
                </li>
            )
        }
        if( info.type === 'chat' ){
            return(
                <li className="notification align-items-center" key={key}>
                    <div className="icon u-col-1">
                        <span className="af-messages"></span>
                    </div>
                    <div className="message u-col-10">
                        {info.content}<br/>
                        Sprawdź <a href="#" onClick={this.goToChat}>Chat</a>
                    </div>
                    <div className="delete u-col-1 text-right">
                        <button className="af-close" onClick={this.deleteMessage} data-id={info.id}></button>
                    </div>
                </li>
            )
        }
    };

    render(){
        if( this.state.errors.length > 0 ){
            return(
                <div className="account_box p-5">
                    <h2 className="text-center">Powiadomienia</h2>
                    <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                </div>
            )
        }
        const {messages} = this.state
        //console.log(messages)
        return(
            <div className="account_box p-5">
                <h2 className="text-center">Powiadomienia</h2>
                {Object.keys(messages).map( (data,index) => (
                    <div key={index}>
                        <div className="date">{data}</div>
                        <ul className="notyfications">
                            {messages[data].map( (msg, key) => (
                                this.renderNotificationList( msg, key )
                            ) )}
                        </ul>
                    </div>
                ))}

            </div>
        )
    }
}