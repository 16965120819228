import { Component } from "react";
import Avatar from "./Avatar";
import axios from "axios";
import config from '../config.json'

export default class UserTableRooms extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    goToRoom = e => {
        e.preventDefault();
        localStorage.setItem('showUser', e.target.dataset.visitor);
        localStorage.setItem('currentView','user-profile');
        window.location.reload(true);
    }
    render(){
        const data = this.props.post
        return(
            <table className="users_list">
                <thead>
                    <tr>
                        <th colSpan="3">
                            {this.props.label}
                        </th>
                    </tr>
                </thead>
                <tbody>
                        <tr key={data.index}>
                            <td className="user">
                                <a href="#" onClick={this.goToRoom} data-visitor={this.props.who}>
                                    <Avatar user_id={data.id}/>
                                </a>
                            </td>
                            <td className="action">
                            </td>
                            <td className="time"><span className="af-clock me-2"></span>{data.time}</td>
                        </tr>
                </tbody>
            </table>
        )
    }
}