function Step7(props)
{
if( props.currentStep !== 7 ){
    return null
}
    return(
        <div>
            <h3 className="header">Twoja wizytówka</h3>
            <p className="lead">Napisz coś krótko o sobie, aby inni mogli Cię lepiej poznać!</p>
            <textarea rows="5" placeholder="Kliknij aby zacząć pisać..." name="user_desc" value={props.user_desc} onChange={props.handleChange}></textarea>
        </div>
    );
}

export default Step7;