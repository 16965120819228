import { Component } from "react";

export default class Settings extends Component{

    render(){
        return(
            <div className="">
                Ustawienia
            </div>
        )
    }
}