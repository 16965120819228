function Step2(props)
{
if( props.currentStep !== 2 ){
    return null
}
    return(
        <div>
            <h3 className="header">Wpisz swoją datę urodzenia</h3>
            <p className="lead"></p>
            <div>
                <input type="date" name="user_birth" value={props.user_birth} onChange={props.handleChange}></input>
            </div>
        </div>
    );
}

export default Step2;