
function Step19(props)
{
if( props.currentStep !== 19 ){
    return null
}
    return(
        <div>
            <h3 className="header">Czego szukasz?</h3>
            <p className="lead mb-5"></p>
            <div className="custom-radio">
                <div className="check">
                    <input type="radio" id="przyjazni" name="user_find" value="przyjazni" checked={props.user_find === "przyjazni"} onChange={props.handleChange} />
                    <label htmlFor="przyjazni">Przyjaźni</label>
                </div>
                <div className="check">
                    <input type="radio" id="milosci" name="user_find" value="milosci" checked={props.user_find === "milosci"} onChange={props.handleChange} />
                    <label htmlFor="milosci">Miłości</label>
                </div>
                <div className="check">
                    <input type="radio" id="znajomosci" name="user_find" value="znajomosci" checked={props.user_find === "znajomosci"} onChange={props.handleChange} />
                    <label htmlFor="znajomosci">Znajomości</label>
                </div>
                <div className="check">
                    <input type="radio" id="find_inne" name="user_find" value="inne" checked={props.user_find === "inne"} onChange={props.handleChange} />
                    <label htmlFor="find_inne">Inne</label>
                </div>
            </div>
        </div>
    );
}

export default Step19;