import { Component } from "react";
import axios from "axios";
import userImage from '../asset/images/default_user.png';
import config from '../config.json'

export default class Avatar extends Component{

    constructor(props){
        super(props)
        this.state = {
            userName: '',
            userImgUrl: '',
            counter: false
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users/${this.props.user_id}`)
            .then(res =>{
                //console.info(res.data)
                if( res.data.user_avatar === undefined || res.data.user_avatar[0] === "" ){
                    this.setState({
                        userName:res.data.user_nick,
                        userImgUrl:userImage
                    })
                }else{
                    this.setState({
                        userName:res.data.user_nick,
                        userImgUrl:res.data.user_avatar
                    })
                }
            })
            .catch(err => {
                console.warn(err)
            })
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/notification/${sessionStorage.getItem('userID')}`)
            .then(responses => {
            //console.log(responses.data[0].type)
                if( responses.data.length > 0 ){
                    responses.data.map((data)=>{
                        if( data.type === "chat" ){
                            /* this.setState({
                                counter: true
                            }) */
                            axios
                            .get(`${config.dbURL}/wp-json/fac/v1/room/${this.props.counter}`)
                            .then(response => {
                                console.log('check room')
                                console.log( response.data[0].user )
                                if( response.data[0] !== undefined ){
                                    if( this.props.user_side === 'delivery' && data.user === sessionStorage.getItem('userID') && sessionStorage.getItem('userID') !== response.data[0].user ){
                                            /* console.log('current user: '+ sessionStorage.getItem('userID'))
                                            console.log('chat user: '+ this.props.user_id)
                                            console.log('chat user type: '+ this.props.user_side)
                                            console.log('chat user data: '+ data.user)
                                            console.log('chat user user: '+ response.data[0].user)
                                            console.log('chat user delivery: '+ response.data[0].delivery) */
                                            this.setState({
                                                counter: true
                                            })
                                        }
                                        if( this.props.user_side === 'user' && data.user === sessionStorage.getItem('userID') && sessionStorage.getItem('userID') !== response.data[0].user ){
                                            /* console.log('current user: '+ sessionStorage.getItem('userID'))
                                            console.log('chat user: '+ this.props.user_id)
                                            console.log('chat user type: '+ this.props.user_side)
                                            console.log('chat user data: '+ data.user)
                                            console.log('chat user user: '+ response.data[0].user)
                                            console.log('chat user delivery: '+ response.data[0].delivery) */
                                            this.setState({
                                                counter: true
                                            })
                                        }
                                        
                                    }
                                })
                        }
                    })
                }
            })
    }

    render(){
        console.log( this.state.counter );
        return(
            <div className="avatar">
                <div className="image">
                    <img src={this.state.userImgUrl} alt={this.state.userName} />
                </div>
                <div className="name">
                    <h5 className="user_name">{this.state.userName}</h5>
                    <span className={ (this.state.counter)?"counter display":"counter" }></span>
                </div>
            </div>
        )
    }
}