import axios from "axios";
import React,{ Component } from "react";
import config from '../config.json'
import Alerts from "./alerts";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

export default class LogedIn extends Component {

    constructor( props ){
        super( props )

        this.state = {
            username:'',
            password: '',
            userName: '',
            token: '',
            loggedIn: false,
            showPwd: false,
            isLoading: false,
            errors: [],
        }
    }

    onFormSubmit = (event) => {
        event.preventDefault();

        const siteUrl = config.dbURL;

        const loginData = {
            "username": this.state.username,
            "password": this.state.password
        };

        this.setState( {isLoading:true}, () => {
            axios.post( 
                `${siteUrl}/wp-json/jwt-auth/v1/token`,
                loginData
             ).then( res => {
                console.log( res.data )
                if( undefined === res.data.token ){
                    //error no access
                    return;
                }else{
                    sessionStorage.setItem( 'token', res.data.token );
                    sessionStorage.setItem( 'userName', res.data.user_display_name );
                    sessionStorage.setItem( 'userEmail', res.data.user_email );
                    this.setState({
                        isLoading: false,
                        token: res.data.token,
                        userName: res.data.user_display_name,
                        loggedIn: true
                    })
                    console.info('user ID:'+res.data)
                    axios.get(`${siteUrl}/wp-json/fac/v1/user/${res.data.user_nicename}`).then( response => {
                        console.info(response.data)
                        sessionStorage.setItem( 'userID', response.data );
                        axios.get(`${siteUrl}/wp-json/fac/v1/users/${response.data}`).then( res => {
                            console.info(res.data)
                            localStorage.setItem('allBaseData', (res.data.user_active)?'true':'false')
                            if( res.data.user_nick !== undefined && res.data.user_nick !== '' ){
                                sessionStorage.setItem( 'userName', res.data.user_nick );
                            }
                            if( res.data.user_activate === "0" ){
                                this.setState({
                                    errors:['warning', 'Konto nie zostało aktywowane.']
                                })
                            }else{
                                sessionStorage.setItem( 'userloggedIn', true );
                                localStorage.setItem('showsCount', 0)
                                window.location.reload(true);
                            }
                        } )
                    } )
                }
             })
             .catch( err => {
                 console.log(err)
             } )
        } );
        //window.location.reload();
    }

    handleOnChange = ( event ) => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        )
    }
    togglePwd = () =>{
        let {showPwd} = this.state
        this.setState({
            showPwd: !showPwd
        })
    }
    responseFacebook(response) {
        console.log(response)
        if( response.email !== undefined ){
            axios.post(
                `${config.dbURL}/wp-json/fac/v1/login/`,
                {
                    'useremail': response.email
                }
            ).then(res=>{
                    sessionStorage.setItem( 'userName', res.data.user_display_name );
                    sessionStorage.setItem( 'userEmail', res.data.user_email );
                    console.info('user ID:'+res.data)
                    axios.get(`${config.dbURL}/wp-json/fac/v1/user/${res.data.user_nicename}`).then( response => {
                        console.info(response.data)
                        sessionStorage.setItem( 'userID', response.data );
                        axios.get(`${config.dbURL}/wp-json/fac/v1/users/${response.data}`).then( res => {
                            console.info(res.data)
                            localStorage.setItem('allBaseData', (res.data.user_active)?'true':'false')
                            if( res.data.user_nick !== undefined && res.data.user_nick !== '' ){
                                sessionStorage.setItem( 'userName', res.data.user_nick );
                            }
                            if( res.data.user_activate === "0" ){
                                this.setState({
                                    errors:['warning', 'Konto nie zostało aktywowane.']
                                })
                            }else{
                                sessionStorage.setItem( 'userloggedIn', true );
                                localStorage.setItem('showsCount', 0)
                                window.location.reload(true);
                            }
                        } )
                    } )
            })
        }
    }
    responseGoogle(response) {
        if( response.profileObj !== undefined ){
            axios.post(
                `${config.dbURL}/wp-json/fac/v1/login/`,
                {
                    'useremail': response.profileObj.email
                }
            ).then(res=>{
                    sessionStorage.setItem( 'userName', res.data.user_display_name );
                    sessionStorage.setItem( 'userEmail', res.data.user_email );
                    console.info('user ID:'+res.data)
                    axios.get(`${config.dbURL}/wp-json/fac/v1/user/${res.data.user_nicename}`).then( response => {
                        console.info(response.data)
                        sessionStorage.setItem( 'userID', response.data );
                        axios.get(`${config.dbURL}/wp-json/fac/v1/users/${response.data}`).then( res => {
                            console.info(res.data)
                            localStorage.setItem('allBaseData', (res.data.user_active)?'true':'false')
                            if( res.data.user_nick !== undefined && res.data.user_nick !== '' ){
                                sessionStorage.setItem( 'userName', res.data.user_nick );
                            }
                            if( res.data.user_activate === "0" ){
                                this.setState({
                                    errors:['warning', 'Konto nie zostało aktywowane.']
                                })
                            }else{
                                sessionStorage.setItem( 'userloggedIn', true );
                                localStorage.setItem('showsCount', 0)
                                window.location.reload(true);
                            }
                        } )
                    } )
            })
        }
    }
    render(){
        const { username, password, showPwd } = this.state;
        if( this.state.errors.length > 0 ){
            return(
                <div className="login_form p-5 mt-md-5">
                    <h2 className="text-center">Zaloguj się</h2>
                    <div className="u-row">
                        <div className="u-col-8 u-col-md-6 mx-auto">
                            <Alerts type={this.state.errors[0]} message={this.state.errors[1]}/>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="login_form">
                <div className="form">
                    <h2>Zaloguj się</h2>
                    <form onSubmit={this.onFormSubmit} >
                        <label htmlFor="username">Adres e-mail</label>
                        <input 
                            type="email" 
                            name="username" 
                            id="username" 
                            value={username}
                            onChange={this.handleOnChange}
                        /><br/>
                        <label htmlFor="password">Hasło</label>
                        <div className={(showPwd)?"password-field show":"password-field hidden"}>
                            <span className="pwd-icon" onClick={this.togglePwd}></span>
                            {
                            ( showPwd )?
                                <input 
                                    type="text" 
                                    name="password" 
                                    id="password" 
                                    value={password}
                                    onChange={this.handleOnChange}
                                />
                                :
                                <input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    value={password}
                                    onChange={this.handleOnChange}
                                />
                            }
                        </div>
                        <button type="submit">
                            Zaloguj
                        </button>
                    </form>
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-6">
                            <FacebookLogin
                                appId="3112143232396396"
                                autoLoad={false}
                                fields="email"
                                callback={this.responseFacebook}
                                textButton="Facebook"
                                icon='fab fa-facebook-f'
                            />
                        </div>
                        <div className="u-col-12 u-col-md-6 google-button">
                            <GoogleLogin
                                clientId="827976105348-aunivakrffivp8d2628ijdilvsoqtmj3.apps.googleusercontent.com"
                                buttonText="Google"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                    <p className="text-center mt-4">
                        <a href={`${config.pageURL}/wp-login.php?action=lostpassword`}  rel="noreferrer">
                            Nie pamiętasz hasła?
                        </a>
                    </p>
                    <p className="text-center">
                        Jesteś nowym użytkownikiem Feelio?&nbsp;
                        <a href={`${config.pageURL}/rejestracja`}  rel="noreferrer">
                            Dołącz teraz!
                        </a>
                    </p>
                    <p>
                        <a href={config.pageURL}  rel="noreferrer" className="back">
                            <span className="af-arrow-right icon-rotate-x"></span> powrót
                        </a>
                    </p>


                </div>
            </div>
        );
    }
}