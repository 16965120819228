import Select from "react-select";
function Step14(props)
{
if( props.currentStep !== 14 ){
    return null
}
    return(
        <div>
            <h3 className="header">Interesują Cię...</h3>
            <p className="lead">Zawęź swoje poszukiwania.</p>
            <Select  
                name="user_sex_intrest" 
                options={[
                    {value:'wszyscy',label:'Wszyscy'},
                    {value:'kobiety',label:'Kobiety'},
                    {value:'mezczyzni',label:'Mężczyźni'}
                ]} 
                placeholder='wybierz'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_sex_intrest}
            />
        </div>
    );
}

export default Step14;