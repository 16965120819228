import { Component } from "react";
import Editavatar from "react-avatar-edit";
import Select from "react-select";
import Slider, { SliderTooltip } from "rc-slider";
import {
    Redirect
} from "react-router-dom";
import axios from "axios";
import 'rc-slider/assets/index.css';
import config from '../config.json'

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
};

export default class EditMyAccount extends Component{

    constructor(props) {
        super(props)
        //const src = '../asset/images/einshtein.jpg'
        const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];
        const uploadLocation = baseUri+'/upload/';
        this.state = {
          preview: '',
          src: '',
          user_email:sessionStorage.getItem('userEmail'),
          user_zodiak:'',
          user_birth:'',
          user_state:'',
          user_city:'',
          user_sex:'',
          user_desc:'',
          user_work:'',
          user_interest:[],
          user_education:'',
          user_languages:'',
          user_country:'',
          user_religion:'',
          user_sex_intrest:'',
          user_status:'',
          user_children:'',
          user_addictions:[],
          user_find:'',
          user_body:'',
          user_search:'',
          selected_options: null
        }
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }
    
    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users/${sessionStorage.getItem('userID')}`)
            .then(res => {
                //console.log(res)
                this.setState({
                    user_zodiak:res.data.user_zodiak,
                    user_birth:res.data.user_birth,
                    user_state:res.data.user_state,
                    user_city:res.data.user_city,
                    user_sex:res.data.user_sex,
                    user_desc:res.data.description,
                    user_work:res.data.user_work,
                    user_interest:res.data.user_interest,
                    user_education:res.data.user_education,
                    user_languages:res.data.user_language,
                    user_country:res.data.user_country,
                    user_religion:res.data.user_religion,
                    user_sex_intrest:res.data.user_sex_intrest,
                    user_status:res.data.user_status,
                    user_children:res.data.user_children,
                    user_addictions:res.data.user_addictions,
                    user_find:res.data.user_goal[0],
                    user_body:res.data.user_body,
                    user_search:'',
                    src: res.data.user_avatar,
                    preview: res.data.user_avatar
                })
                console.log(res.data.user_education);
                console.log(res.data.user_sex);
                console.log(res.data.user_goal);
                console.log(res.data.user_goal[0]);
            })
            .catch(err => {
                console.log( err )
            })
    }

    onClose() {
        this.setState({preview: ''})
    }
    
    onCrop(preview) {
        this.setState({preview})
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 40000000){
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    handleChange = event =>{
        const {name, value} = event.target
        this.setState({
          [name]: value
        }) 
    }

    handleChangeIntrest = event =>{
        let {user_interest} = this.state
        user_interest.push({value:event.target.value})
        this.setState({
            user_interest
        });
    }

    handleChangeAddictions = event =>{
        let {user_addictions} = this.state
        console.log(user_addictions)
        user_addictions.push({value:event.target.value})
        console.log(user_addictions)
        this.setState({
            user_addictions
        });
    }

    handleChangeZodiak = (user_zodiak) => {
        this.setState({user_zodiak});
    };

    handleChangeSex = (user_sex) => {
        this.setState({user_sex});
    };

    handleChangeEducation = (user_education) => {
        this.setState({user_education});
    };

    handleChangeLanguage = (user_languages) => {
        this.setState({user_languages});
    };

    handleSelectCountry = (user_country) => {
        this.setState({user_country});
    };

    handleSelectSexIntrest = (user_sex_intrest) => {
        this.setState({user_sex_intrest});
    };

    handleSelectStatus = (user_status) => {
        this.setState({user_status});
    };

    handleSelectChildren = (user_children) => {
        this.setState({user_children});
    };

    handleSelectBody = (user_body) => {
        this.setState({user_body});
    };

    saveProfile = () =>{
        const {preview, user_zodiak, user_birth, user_state, user_country, user_city, user_sex, user_desc, user_work, user_interest, user_education, user_languages, user_religion, user_sex_intrest, user_status, user_children, user_addictions, user_find, user_body, user_search} = this.state;
        let userData = {
            description: user_desc,
            user_avatar: preview,
            user_city: user_city,
            user_birth: user_birth,
            user_sex: user_sex,
            user_work: user_work,
            user_interest: user_interest,
            user_education: user_education,
            user_religion: user_religion,
            user_sex_intrest: user_sex_intrest,
            user_status: user_status,
            user_children: user_children,
            user_zodiak: user_zodiak,
            user_body: user_body,
            user_country: user_country,
            user_language: user_languages,
            user_goal: user_find,
            user_addictions: user_addictions,
            user_state: user_state,
            user_nick: sessionStorage.getItem('userName'),
            user_active: sessionStorage.getItem('userID')
        }
        axios
            .post(`${config.dbURL}/wp-json/fac/v1/users/${sessionStorage.getItem('userID')}`, userData)
            .then(res => {
                console.log(res)
                localStorage.setItem('currentView', 'my-profile');
                window.location.reload(true)
            })
            .catch(err => {
                console.log(err)
            })
        //return <Redirect to='/my-account'/>
    }
    
    openGallery = () =>{
        localStorage.setItem('currentView', 'my-gallery')
        window.location.reload(true)
    }

    render(){
        //console.log( this.state.src )
        const languages = [
            {value:'akan',label:'akan'},
            {value:'amharski',label:'amharski'},
            {value:'angielski',label:'angielski'},
            {value:'arabski',label:'arabski'},
            {value:'asamski',label:'asamski'},
            {value:'awadhi',label:'awadhi'},
            {value:'azerbejdżański',label:'azerbejdżański'},
            {value:'beludżi',label:'beludżi'},
            {value:'bengalski',label:'bengalski'},
            {value:'bhojpuri',label:'bhojpuri'},
            {value:'białoruski',label:'białoruski'},
            {value:'birmańczyk',label:'birmańczyk'},
            {value:'cebuano',label:'cebuano'},
            {value:'chewa',label:'chewa'},
            {value:'chhattisgarhi',label:'chhattisgarhi'},
            {value:'chittagoński',label:'chittagoński'},
            {value:'czeski',label:'czeski'},
            {value:'dekan',label:'dekan'},
            {value:'dhundhari',label:'dhundhari'},
            {value:'Francuski',label:'Francuski'},
            {value:'fula',label:'fula'},
            {value:'gan',label:'gan'},
            {value:'grecki',label:'grecki'},
            {value:'gudżarati',label:'gudżarati'},
            {value:'haryanvi',label:'haryanvi'},
            {value:'Hausa',label:'Hausa'},
            {value:'hinduski',label:'hinduski'},
            {value:'hiszpański',label:'hiszpański'},
            {value:'hmong',label:'hmong'},
            {value:'holenderski',label:'holenderski'},
            {value:'igbo',label:'igbo'},
            {value:'ilocano',label:'ilocano'},
            {value:'japoński',label:'japoński'},
            {value:'jawajski',label:'jawajski'},
            {value:'jin',label:'jin'},
            {value:'joruba',label:'joruba'},
            {value:'kakka',label:'kakka'},
            {value:'Kannada',label:'Kannada'},
            {value:'kazachski',label:'kazachski'},
            {value:'keczua',label:'keczua'},
            {value:'khmerski',label:'khmerski'},
            {value:'kinyarwanda',label:'kinyarwanda'},
            {value:'kirundi',label:'kirundi'},
            {value:'konkani',label:'konkani'},
            {value:'koreański',label:'koreański'},
            {value:'kreolski haitański',label:'kreolski haitański'},
            {value:'kurdyjski',label:'kurdyjski'},
            {value:'maduski',label:'maduski'},
            {value:'magahi',label:'magahi'},
            {value:'maithili',label:'maithili'},
            {value:'malajalam',label:'malajalam'},
            {value:'malajski',label:'malajski'},
            {value:'malgaski',label:'malgaski'},
            {value:'mandaryński',label:'mandaryński'},
            {value:'Marathi',label:'Marathi'},
            {value:'marwari',label:'marwari'},
            {value:'mossi',label:'mossi'},
            {value:'nepalski',label:'nepalski'},
            {value:'niemiecki',label:'niemiecki'},
            {value:'Odia',label:'Odia'},
            {value:'oromo',label:'oromo'},
            {value:'paszto',label:'paszto'},
            {value:'pendżabski',label:'pendżabski'},
            {value:'perski',label:'perski'},
            {value:'polski',label:'polski'},
            {value:'portugalski',label:'portugalski'},
            {value:'rosyjski',label:'rosyjski'},
            {value:'rumuński',label:'rumuński'},
            {value:'saraiki',label:'saraiki'},
            {value:'serbsko-chorwacki',label:'serbsko-chorwacki'},
            {value:'sindhi',label:'sindhi'},
            {value:'somalijski',label:'somalijski'},
            {value:'sundajski',label:'sundajski'},
            {value:'sylheti',label:'sylheti'},
            {value:'syngaleski',label:'syngaleski'},
            {value:'szona',label:'szona'},
            {value:'szwedzki',label:'szwedzki'},
            {value:'tagalski',label:'tagalski'},
            {value:'tajski',label:'tajski'},
            {value:'tamil',label:'tamil'},
            {value:'telugu',label:'telugu'},
            {value:'turecki',label:'turecki'},
            {value:'turkmeński',label:'turkmeński'},
            {value:'ujgurski',label:'ujgurski'},
            {value:'ukraiński',label:'ukraiński'},
            {value:'urdu',label:'urdu'},
            {value:'uzbecki',label:'uzbecki'},
            {value:'węgierski',label:'węgierski'},
            {value:'wietnamski',label:'wietnamski'},
            {value:'włoski',label:'włoski'},
            {value:'wu',label:'wu'},
            {value:'xhosa',label:'xhosa'},
            {value:'xiang',label:'xiang'},
            {value:'zhuang',label:'zhuang'},
            {value:'zulus',label:'zulus'}
        ]
        const country = [
            {value:'AD', label:'Andora'},
            {value:'AE', label:'Zjednoczone Emiraty Arabskie łącznie z Abu Drabi, Dubai, Sharjah, Ajman, Umm al Qaiwain Ras al Khaimh i Fujairh '},
            {value:'AF', label:'Afganistan'},
            {value:'AG', label:'Antigua i Barbuda'},
            {value:'AI', label:'Anguilla'},
            {value:'AL', label:'Albania'},
            {value:'AM', label:'Armenia'},
            {value:'AO', label:'Angola łącznie z Kabinda'},
            {value:'AQ', label:'Antarktyda'},
            {value:'AR', label:'Argentyna'},
            {value:'AS', label:'Samoa Amerykańskie'},
            {value:'AT', label:'Austria'},
            {value:'AU', label:'Australia'},
            {value:'AW', label:'Aruba'},
            {value:'AZ', label:'Azerbejdżan'},
            {value:'BA', label:'Bośnia i Hercegowina'},
            {value:'BB', label:'Barbados'},
            {value:'BD', label:'Bangladesz'},
            {value:'BE', label:'Belgia'},
            {value:'BF', label:'Burkina Faso'},
            {value:'BG', label:'Bułgaria'},
            {value:'BH', label:'Bahrajn'},
            {value:'BI', label:'Burundi'},
            {value:'BJ', label:'Benin'},
            {value:'BM', label:'Bermudy'},
            {value:'BN', label:'Brunei Darussalam'},
            {value:'BO', label:'Boliwia'},
            {value:'BQ', label:'Bonaire, St Eustatius i Saba'},
            {value:'BR', label:'Brazylia'},
            {value:'BS', label:'Bahamy'},
            {value:'BT', label:'Bhutan'},
            {value:'BV', label:'Wyspa Bouveta'},
            {value:'BW', label:'Botswana'},
            {value:'BY', label:'Białoruś'},
            {value:'BZ', label:'Belize'},
            {value:'CA', label:'Kanada'},
            {value:'CC', label:'Wyspy Kokosowe (Keelinga)'},
            {value:'CD', label:'Kongo, Republika Demokratyczna'},
            {value:'CF', label:'Republika Środkowej Afryki'},
            {value:'CG', label:'Kongo'},
            {value:'CH', label:'Szwajcaria'},
            {value:'CI', label:'Wybrzeże Kości Słoniowej'},
            {value:'CK', label:'Wyspy Cooka'},
            {value:'CL', label:'Chile'},
            {value:'CM', label:'Kamerun'},
            {value:'CN', label:'Chiny'},
            {value:'CO', label:'Kolumbia'},
            {value:'CR', label:'Kostaryka'},
            {value:'CU', label:'Kuba'},
            {value:'CV', label:'Wyspy Zielonego Przylądka'},
            {value:'CW', label:'Curacao'},
            {value:'CX', label:'Wyspa Bożego Narodzenia'},
            {value:'CY', label:'Cypr'},
            {value:'CZ', label:'Republika Czeska'},
            {value:'DE', label:'Niemcy łącznie z wyspą Helgoland'},
            {value:'DJ', label:'Dżibuti'},
            {value:'DK', label:'Dania'},
            {value:'DM', label:'Dominika'},
            {value:'DO', label:'Republika Dominikany'},
            {value:'DZ', label:'Algieria'},
            {value:'EC', label:'Ekwador łącznie z wyspami Galapagos'},
            {value:'EE', label:'Estonia'},
            {value:'EG', label:'Egipt'},
            {value:'ER', label:'Erytrea'},
            {value:'ES', label:'Hiszpania łącznie z wyspami Baleary, Kanaryjskie oraz miastami Ceuta i Melilla w Afryce Pónocnej'},
            {value:'ET', label:'Etiopia'},
            {value:'FI', label:'Finlandia łącznie z wyspami Alandzkimi '},
            {value:'FJ', label:'Republika Fidżi'},
            {value:'FK', label:'Wyspy Falklandy (Malwiny)'},
            {value:'FM', label:'Federacja Mikronezji'},
            {value:'FO', label:'Wyspy Owcze'},
            {value:'FR', label:'Francja łącznie z Monako, Gujaną Francuską, Martyniką, Gwadelupą i Reunion, Saint Barthelemy, Saint Martin, Mayotte oraz Saint Pierre i Miquelon'},
            {value:'GA', label:'Gabon'},
            {value:'GB', label:'Wielka Brytania'},
            {value:'GD', label:'Grenada'},
            {value:'GE', label:'Gruzja'},
            {value:'GG', label:'Guernsey'},
            {value:'GH', label:'Ghana'},
            {value:'GI', label:'Gibraltar'},
            {value:'GL', label:'Grenlandia'},
            {value:'GM', label:'Gambia'},
            {value:'GN', label:'Gwinea'},
            {value:'GQ', label:'Gwinea Równikowa'},
            {value:'GR', label:'Grecja'},
            {value:'GS', label:'Georgia Poóudniowa i Sandwich Południowy'},
            {value:'GT', label:'Gwatemala'},
            {value:'GU', label:'Guam'},
            {value:'GW', label:'Gwinea Bissau'},
            {value:'GY', label:'Gujana'},
            {value:'HK', label:'Hongkong'},
            {value:'HM', label:'Wyspa Heard i Wyspy McDonald'},
            {value:'HN', label:'Honduras'},
            {value:'HR', label:'Chorwacja'},
            {value:'HT', label:'Haiti'},
            {value:'HU', label:'Węgry'},
            {value:'ID', label:'Indonezja'},
            {value:'IE', label:'Irlandia'},
            {value:'IL', label:'Izrael'},
            {value:'IM', label:'Wyspa Man'},
            {value:'IN', label:'India'},
            {value:'IO', label:'Brytyjskie Terytorium Oceanu Indyjskiego łącznie z wyspami Czagos'},
            {value:'IQ', label:'Irak'},
            {value:'IR', label:'Iran'},
            {value:'IS', label:'Islandia'},
            {value:'IT', label:'Włochy'},
            {value:'JE', label:'Jersey '},
            {value:'JM', label:'Jamajka'},
            {value:'JO', label:'Jordania'},
            {value:'JP', label:'Japonia'},
            {value:'KE', label:'Kenia'},
            {value:'KG', label:'Kirgistan'},
            {value:'KH', label:'Kambodża (Kampucza)'},
            {value:'KI', label:'Kiribati'},
            {value:'KM', label:'Komory'},
            {value:'KN', label:'Saint Kitts i Nevis'},
            {value:'KP', label:'Koreańska Republika Ludowo-Demokratyczna (Korea Pónocna)'},
            {value:'KR', label:'Republika Korei (Korea Południowa)'},
            {value:'KW', label:'Kuwejt'},
            {value:'KY', label:'Kajmany'},
            {value:'KZ', label:'Kazachstan'},
            {value:'LA', label:'Laos'},
            {value:'LB', label:'Liban'},
            {value:'LC', label:'Saint Lucia'},
            {value:'LI', label:'Lichtenstein'},
            {value:'LK', label:'Sri Lanka'},
            {value:'LR', label:'Liberia'},
            {value:'LS', label:'Lesotho'},
            {value:'LT', label:'Litwa'},
            {value:'LU', label:'Luksemburg'},
            {value:'LV', label:'Łotwa'},
            {value:'LY', label:'Libia'},
            {value:'MA', label:'Maroko łącznie Saharą Zachodnią'},
            {value:'MD', label:'Mołdowa'},
            {value:'ME', label:'Czarnogóra'},
            {value:'MG', label:'Madagaskar'},
            {value:'MH', label:'Wyspy Marshalla'},
            {value:'MK', label:'Macedonia'},
            {value:'ML', label:'Mali'},
            {value:'MM', label:'Myanmar (Birma)'},
            {value:'MN', label:'Mongolia'},
            {value:'MO', label:'Makao'},
            {value:'MP', label:'Mariany Północne'},
            {value:'MR', label:'Mauretania'},
            {value:'MS', label:'Montserrat'},
            {value:'MT', label:'Malta'},
            {value:'MU', label:'Mauritius'},
            {value:'MV', label:'Malediwy'},
            {value:'MW', label:'Malawi'},
            {value:'MX', label:'Meksyk'},
            {value:'MY', label:'Malezja'},
            {value:'MZ', label:'Mozambik'},
            {value:'NA', label:'Namibia'},
            {value:'NC', label:'Nowa Kaledonia'},
            {value:'NE', label:'Niger'},
            {value:'NF', label:'Wyspa Norfolk'},
            {value:'NG', label:'Nigeria'},
            {value:'NI', label:'Nikaragua'},
            {value:'NL', label:'Niderlandy'},
            {value:'NO', label:'Norwegia łącznie z wyspami Svalbard i Jan Mayen'},
            {value:'NP', label:'Nepal'},
            {value:'NR', label:'Nauru'},
            {value:'NU', label:'Niue'},
            {value:'NZ', label:'Nowa Zelandia'},
            {value:'OM', label:'Oman łącznie z wyspami Kuria Muria'},
            {value:'PA', label:'Panama'},
            {value:'PE', label:'Peru'},
            {value:'PF', label:'Polinezja Francuska'},
            {value:'PG', label:'Papua Nowa Gwinea'},
            {value:'PH', label:'Filipiny'},
            {value:'PK', label:'Pakistan'},
            {value:'PN', label:'Pitcairn'},
            {value:'PL', label:'Polska'},
            {value:'PS', label:'Palestyna'},
            {value:'PT', label:'Portugalia łącznie z wyspami Acores (Azory) i Madeira'},
            {value:'PW', label:'Palau'},
            {value:'PY', label:'Paragwaj'},
            {value:'QA', label:'Katar'},
            {value:'RO', label:'Rumunia'},
            {value:'RS', label:'Serbia'},
            {value:'RU', label:'Federacja Rosyjska'},
            {value:'RW', label:'Rwanda'},
            {value:'SA', label:'Arabia Saudyjska'},
            {value:'SB', label:'Wyspy Salomona'},
            {value:'SC', label:'Seszele'},
            {value:'SD', label:'Sudan'},
            {value:'SE', label:'Szwecja'},
            {value:'SG', label:'Singapur'},
            {value:'SH', label:'Wyspa Świętej Heleny'},
            {value:'SI', label:'Słowenia'},
            {value:'SK', label:'Słowacja'},
            {value:'SL', label:'Sierra Leone'},
            {value:'SM', label:'San Marino'},
            {value:'SN', label:'Senegal'},
            {value:'SO', label:'Somalia'},
            {value:'SR', label:'Surinam'},
            {value:'ST', label:'Wyspy Świętego Tomasza i Ksi���ca'},
            {value:'SV', label:'Salwador'},
            {value:'SX', label:'Sint Maarten'},
            {value:'SY', label:'Syria'},
            {value:'SZ', label:'Swaziland'},
            {value:'TC', label:'Wyspy Turks i Caicos'},
            {value:'TD', label:'Czad'},
            {value:'TF', label:'Francuskie Terytoria Południowe'},
            {value:'TG', label:'Togo'},
            {value:'TH', label:'Tajlandia'},
            {value:'TJ', label:'Tadżykistan'},
            {value:'TK', label:'Tokelau'},
            {value:'TL', label:'Timor Wschodni'},
            {value:'TM', label:'Turkmenistan'},
            {value:'TN', label:'Tunezja'},
            {value:'TO', label:'Tonga'},
            {value:'TR', label:'Turcja'},
            {value:'TT', label:'Trynidad i Tobago'},
            {value:'TV', label:'Tuvalu'},
            {value:'TW', label:'Tajwan'},
            {value:'TZ', label:'Tanzania łącznie z wyspami Tanganika, Zanzibar i Pemba'},
            {value:'UA', label:'Ukraina'},
            {value:'UG', label:'Uganda'},
            {value:'UM', label:'Terytoria Zamorskie Stanów Zjednoczonych'},
            {value:'US', label:'Stany Zjednoczone Ameryki Północnej łącznie z Portoryko i Navassa'},
            {value:'UY', label:'Urugwaj'},
            {value:'UZ', label:'Uzbekistan'},
            {value:'VA', label:'Watykan'},
            {value:'VC', label:'Saint Vincent i Grenadyny'},
            {value:'VE', label:'Wenezuela'},
            {value:'VG', label:'Brytyjskie Wyspy Dziewicze'},
            {value:'VI', label:'Wyspy Dziewicze Stanów Zjednoczonych'},
            {value:'VN', label:'Wietnam'},
            {value:'VU', label:'Vanuatu'},
            {value:'WF', label:'Wallis i Futuna'},
            {value:'WS', label:'Samoa'},
            {value:'XK', label:'Kosowo'},
            {value:'YE', label:'Jemen łącznie z wyspami Perim, Kamaran, Socotra'},
            {value:'ZA', label:'Republika Południowej Afryki'},
            {value:'ZM', label:'Zambia'},
            {value:'ZW', label:'Zimbabwe'}
        ]      
        const marks = {
            0:'0km',
            150:'150km'
        }
        return(
            <div className="account_box">
                <div className="u-row">
                    <div className="u-col-12 u-col-md-5 mx-auto text-center py-4">
                        <div className="emas">
                            <h5>Nazwa/Nick</h5>
                            <p>{sessionStorage.getItem('userName')}</p>
                        </div>
                        <div className="emas editable">
                            <h5>Adres e-mail</h5>
                            <input type="email" name="user_email" value={this.state.user_email} onChange={this.handleChange} />
                        </div>
                        <div className="emas editable text-center">
                            <h5>Avatar</h5>
                            <div className="avatar_form">
                                <Editavatar
                                    width={170}
                                    height={170}
                                    onCrop={this.onCrop}
                                    onClose={this.onClose}
                                    onBeforeFileLoad={this.onBeforeFileLoad}
                                    src={this.state.src}
                                    label='+'
                                    cropRadius='65'
                                    name="user_avatar"
                                />
                                <img src={this.state.preview} alt="Preview" />
                            </div>
                        </div>
                        <div className="emas editable">
                            <h5>Twoja galeria</h5>
                            <p>Tutaj możesz dodawać i usuwać swoje zdjęcia. Pojawią się one w galerii na Twoim głównym profilu.</p>
                            <button className="button-outline" onClick={this.openGallery}>EDYTUJ GALERIĘ</button>
                            <h5 className="premium-decor">PREMIUM</h5>
                            <button className="button-outline premium" disabled><span className="af-star"></span>&nbsp;galeria instagram</button>
                        </div>
                        <div className="emas editable">
                            <h5>Data urodzenia</h5>
                            <Select  
                                name="user_zodiak" 
                                options={[
                                    {value:'wodnik',label:'Wodnik'},
                                    {value:'ryby',label:'Ryby'},
                                    {value:'baran',label:'Baran'},
                                    {value:'byk',label:'Byk'},
                                    {value:'bliznieta',label:'Bliźnięta'},
                                    {value:'rak',label:'Rak'},
                                    {value:'panna',label:'Panna'},
                                    {value:'waga',label:'Waga'},
                                    {value:'skorpion',label:'Skorpion'},
                                    {value:'strzelec',label:'Strzelec'},
                                    {value:'koziorozec',label:'Koziorożec'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleChangeZodiak}
                                value={this.state.user_zodiak}
                            />
                            <input type="date" name="user_birth" value={this.state.user_birth} onChange={this.handleChange}/>
                        </div>
                        <div className="emas editable">
                            <h5>Miejscowość</h5>
                            <input type="text" name="user_state" value={this.state.user_state} onChange={this.handleChange} />
                            <input type="text" name="user_city" value={this.state.user_city} onChange={this.handleChange} />
                        </div>
                        <div className="emas editable">
                            <h5>Płeć</h5>
                            <Select  
                                name="user_sex" 
                                options={[
                                    {value:'kobieta',label:'Kobieta'},
                                    {value:'mezczyzna',label:'Mężczyzna'},
                                    {value:'inne',label:'Inne'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleChangeSex}
                                value={this.state.user_sex}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Twoja wizytówka</h5>
                            <textarea name="user_desc" value={this.state.user_desc} onChange={this.handleChange}></textarea>
                        </div>
                        <div className="emas editable">
                            <h5>Gdzie pracujesz</h5>
                            <input type="text" name="user_work" value={this.state.user_work} onChange={this.handleChange} />
                        </div>
                        <div className="emas editable">
                            <h5>Zainteresowania</h5>
                            <div className="badge_checkbox">
                                <div className="check">
                                    <input type="checkbox" id="kulinaria" name="user_intrest" value="kulinaria" checked={this.state.user_interest.find(element=> element === "kulinaria")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="kulinaria">Kulinaria</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="motoryzacja" name="user_intrest" value="motoryzacja" checked={this.state.user_interest.find(element=> element === "motoryzacja")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="motoryzacja">Motoryzacja</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="taniec" name="user_intrest" value="taniec" checked={this.state.user_interest.find(element=> element === "taniec")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="taniec">Taniec</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="bieganie" name="user_intrest" value="bieganie" checked={this.state.user_interest.find(element=> element === "bieganie")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="bieganie">Bieganie</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="sport" name="user_intrest" value="sport" checked={this.state.user_interest.find(element=> element === "sport")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="sport">Sport</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="sztuka" name="user_intrest" value="sztuka" checked={this.state.user_interest.find(element=> element === "sztuka")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="sztuka">Sztuka</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="muzyka" name="user_intrest" value="muzyka" checked={this.state.user_interest.find(element=> element === "muzyka")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="muzyka">Muzyka</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="ogrod" name="user_intrest" value="ogrod" checked={this.state.user_interest.find(element=> element === "ogrod")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="ogrod">Ogród</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="filmy" name="user_intrest" value="filmy" checked={this.state.user_interest.find(element=> element === "filmy")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="filmy">Filmy i seriale</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="majsterkowanie" name="user_intrest" value="majsterkowanie" checked={this.state.user_interest.find(element=> element === "majsterkowanie")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="majsterkowanie">Majsterkowanie</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="netflix" name="user_intrest" value="netflix" checked={this.state.user_interest.find(element=> element === "netflix")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="netflix">Netflix</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="woda" name="user_intrest" value="woda" checked={this.state.user_interest.find(element=> element === "woda")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="woda">Sporty wodne</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id="spiew" name="user_intrest" value="spiew" checked={this.state.user_interest.find(element=> element === "spiew")} onChange={this.handleChangeIntrest} />
                                    <label htmlFor="spiew">Śpiew</label>
                                </div>
                            </div>
                        </div>
                        <div className="emas editable">
                            <h5>Twoje wykształcenie</h5>
                            <Select  
                                name="user_education" 
                                options={[
                                    {value:'wyzsze',label:'Wyższe'},
                                    {value:'srednie',label:'Średnie'},
                                    {value:'podstawowe',label:'Podstawowe'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleChangeEducation}
                                value={this.state.user_education}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Znajomość języków obcych</h5>
                            <Select 
                                isMulti 
                                name="user_languages" 
                                options={languages} 
                                placeholder='wybierz języki'
                                className="multi-select"
                                onChange={this.handleChangeLanguage}
                                value={this.state.user_languages}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Twoja narodowość</h5>
                            <Select  
                                name="user_country" 
                                options={country} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleSelectCountry}
                                value={this.state.user_country}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Wyznanie</h5>
                            <input type="text" name="user_religion" value={this.state.user_religion} onChange={this.handleChange} />
                        </div>
                        <div className="emas editable">
                            <h5>Interesują Cię...</h5>
                            <Select  
                                name="user_sex_intrest" 
                                options={[
                                    {value:'wszyscy',label:'Wszyscy'},
                                    {value:'kobiety',label:'Kobiety'},
                                    {value:'mezczyzni',label:'Mężczyźni'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleSelectSexIntrest}
                                value={this.state.user_sex_intrest}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Stan cywilny</h5>
                            <Select  
                                name="user_status" 
                                options={[
                                    {value:'panna',label:'Panna'},
                                    {value:'kawaler',label:'Kawaler'},
                                    {value:'zwiazek',label:'W związku'},
                                    {value:'rozwod',label:'Po rozwodzie'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleSelectStatus}
                                value={this.state.user_status}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Czy masz dzieci?</h5>
                            <Select  
                                name="user_children" 
                                options={[
                                    {value:'tak',label:'Tak'},
                                    {value:'nie',label:'Nie'},
                                    {value:'chce',label:'Nie, ale chcę mieć'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleSelectChildren}
                                value={this.state.user_children}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Nałogi</h5>
                            <div className="custom-checkbox">
                                <div className="check">
                                    <input type="checkbox" id="narkotyki" name="user_addictions" value="narkotyki" checked={this.state.user_addictions.find(element=> element === "narkotyki")} onChange={this.handleChangeAddictions} />
                                    <label htmlFor="narkotyki">Narkotyki</label>
                                </div>                                    
                                <div className="check">
                                    <input type="checkbox" id="alkohol" name="user_addictions" value="alkohol" checked={this.state.user_addictions.find(element=> element === "alkohol")} onChange={this.handleChangeAddictions} />
                                    <label htmlFor="alkohol">Alkohol</label>
                                </div>                                    
                                <div className="check">
                                    <input type="checkbox" id="papierosy" name="user_addictions" value="papierosy" checked={this.state.user_addictions.find(element=> element === "papierosy")} onChange={this.handleChangeAddictions} />
                                    <label htmlFor="papierosy">Papierosy</label>
                                </div>                                    
                                <div className="check">
                                    <input type="checkbox" id="addictions_inne" name="user_addictions" value="inne" checked={this.state.user_addictions.find(element=> element === "inne")} onChange={this.handleChangeAddictions} />
                                    <label htmlFor="addictions_inne">Inne</label>
                                </div>                                    
                                <div className="check">
                                    <input type="checkbox" id="brak" name="user_addictions" value="brak" checked={this.state.user_addictions.find(element=> element === "brak")} onChange={this.handleChangeAddictions} />
                                    <label htmlFor="brak">Nie mam nałogów</label>
                                </div>                                    
                            </div>
                        </div>
                        <div className="emas editable">
                            <h5>Czego szukasz</h5>
                            <div className="custom-radio">
                                <div className="check">
                                    <input type="radio" id="przyjazni" name="user_find" value="przyjazni" checked={this.state.user_find === "przyjazni"} onChange={this.handleChange} />
                                    <label htmlFor="przyjazni">Przyjaźni</label>
                                </div>
                                <div className="check">
                                    <input type="radio" id="milosci" name="user_find" value="milosci" checked={this.state.user_find === "milosci"} onChange={this.handleChange} />
                                    <label htmlFor="milosci">Miłości</label>
                                </div>
                                <div className="check">
                                    <input type="radio" id="znajomosci" name="user_find" value="znajomosci" checked={this.state.user_find === "znajomosci"} onChange={this.handleChange} />
                                    <label htmlFor="znajomosci">Znajomości</label>
                                </div>
                                <div className="check">
                                    <input type="radio" id="find_inne" name="user_find" value="inne" checked={this.state.user_find === "inne"} onChange={this.handleChange} />
                                    <label htmlFor="find_inne">Inne</label>
                                </div>
                            </div>
                        </div>
                        <div className="emas editable">
                            <h5>Typ sylwetki</h5>
                            <Select  
                                name="user_body" 
                                options={[
                                    {value:'atletyczna',label:'Atletyczna'},
                                    {value:'szczuply',label:'Szczupła'},
                                    {value:'srednia',label:'Średnia'},
                                    {value:'gruby',label:'Parę kilo za dużo'}
                                ]} 
                                placeholder='wybierz'
                                className="multi-select"
                                onChange={this.handleSelectBody}
                                value={this.state.user_body}
                            />
                        </div>
                        <div className="emas editable">
                            <h5>Promień wyszukiwania</h5>
                            <p>Określ odległość w których chcesz wyszukiwać nowe znajomości.</p>
                            <Range 
                                min={0}
                                max={150}
                                step={5}
                                defaultValue={[15,100]}
                                onChange={this.handleChange}
                                name="user_search"
                                className="pk_range"
                                marks={marks}
                                tipFormatter={
                                    value => `${value} km`
                                }
                            />
                            <small className="lead">Aby wyszukać osoby powyżej 150 km wybierz wariant <strong>PREMIUM</strong>.</small>
                        </div>
                        <div className="text-center">
                            <button onClick={this.saveProfile} className="btn btn-voilet mt-3">zapisz</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}