import { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import heart from '../asset/images/heart-outline.svg';
import config from '../config.json'

export default class UserView extends Component{
    constructor(props){
        super(props)
        this.state = {
            userImgUrl: '',
            userMeta: [],
            userDesc:'',
            user_zodiak:'',
          user_birth:'',
          user_state:'',
          user_city:'',
          user_sex:'',
          user_desc:'',
          user_work:'',
          user_interest:[],
          user_education:'',
          user_languages:'',
          user_country:'',
          user_religion:'',
          user_sex_intrest:'',
          user_status:'',
          user_children:'',
          user_addictions:[],
          user_find:'',
          user_body:'',
          user_search:'',
          user_gallery:'<div class="loadingio-spinner-spin-62gvgn0mc67"><div class="ldio-sqanrd4v17"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div></div>',
          fir_status: ''
        }
    }

    componentDidMount(){
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/users/${this.props.user}`)
            .then(res => {
                //console.log(res)
                this.setState({
                    user_zodiak:res.data.user_zodiak,
                    user_birth:res.data.user_birth,
                    user_state:res.data.user_state,
                    user_city:res.data.user_city,
                    user_sex:res.data.user_sex,
                    user_desc:res.data.description,
                    user_work:res.data.user_work,
                    user_interest:res.data.user_interest,
                    user_education:res.data.user_education,
                    user_languages:res.data.user_language,
                    user_country:res.data.user_country,
                    user_religion:res.data.user_religion,
                    user_sex_intrest:res.data.user_sex_intrest,
                    user_status:res.data.user_status,
                    user_children:res.data.user_children,
                    user_addictions:res.data.user_addictions,
                    user_find:res.data.user_goal,
                    user_body:res.data.user_body,
                    user_nick:res.data.user_nick,
                    user_search:'',
                    src: res.data.user_avatar,
                    preview: res.data.user_avatar
                })
                //console.log(res.data.user_education);
            })
            .catch(err => {
                console.warn(err)
            })
            axios
            .get(`${config.dbURL}/wp-json/fac/v1/gallery/${this.props.user}`)
            .then(res=>{
                //console.log(res.data)
                if(res.data.code !== 'no_data'){
                    this.setState({
                        user_gallery:res.data[0].gallery
                    })
                }else{
                    this.setState(
                        {
                            user_gallery:'<div class="u-col-10 mx-auto text-center py-3">Brak zdjęć użytkownika</div>'
                        }
                    )
                }
            })
            axios
            .get(`${config.dbURL}/wp-json/fac/v1/checkfriend/${sessionStorage.getItem('userID')}/${this.props.user}`)
            .then(res => {
                //console.log(res.data);
                if( res.data.code === 'no_data' ){
                    this.setState({
                        fir_status: 'none'
                    })
                }else if( res.data[0].status === '0' ){
                    this.setState({
                        fir_status: 'disallow'
                    })
                }else if( res.data[0].status === '1' ){
                    this.setState({
                        fir_status: 'allow'
                    })
                }
            })
    }

    calculateAge = birth =>{
        if( birth !== undefined ){
            let today = new Date();
            let birthDate = new Date(this.state.user_birth);
    
            let age_now = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if( m < 0 || (m === 0 && today.getDate() < birthDate.getDate()) ){
                age_now--;
            }
            return(age_now)
        }else{
            return null
        }
    }
    emptyData = () => {
        return(
            <span className="hidden-data">
                Ukryte
            </span>
        )
    }
    addFriend = () => {
        const currUser = this.props.user;
        const authorID = sessionStorage.getItem('userID');
        //console.log(currUser)
        //console.log(authorID)
        axios.post(`${config.dbURL}/wp-json/fac/v1/favourite/${authorID}`,
         {
            who:currUser,
         }
         ).then(res =>{
             //notify
             
         });
         axios.post(`${config.dbURL}/wp-json/fac/v1/notification/${currUser}`,{
            content: 'Otrzymałeś zaproszenie',
            type: 'newfriends'
         }).then(response=>{
             //redirect
             localStorage.setItem('currentView','call-out')
             window.location.reload(true)
         }
         )
    }
    delFriend = () => {
        const currUser = this.props.user;
        const authorID = sessionStorage.getItem('userID');
        //console.log(currUser)
        //console.log(authorID)
        axios.post(`${config.dbURL}/wp-json/fac/v1/deletefriend/${authorID}/${currUser}`)
            .then(()=>{
                window.location.reload(true)
            });
    }
    renderFiendsButton = (getStatus) =>{
        
        if( getStatus === 'none' ){
            console.log('brak danych')
            return(
                <button onClick={this.addFriend} className="btn btn-outline-white">Wyślij zaproszenie</button>
                );
        }else if( getStatus === 'disallow' ){
            console.log('oczekuje')
            return(
                <button className="btn btn-outline-white" disabled>Oczekuje na akceptację</button>
                );
        }else if( getStatus === 'allow' ){
            console.log('usun')
            return(<><button onClick={this.delFriend} className="btn btn-outline-white">Usuń ze znajomych</button></>)
        }
    }

    render(){
        const baseUri = window.location.href.split('/')[0]+'//'+window.location.href.split('/')[2];
        return(
            <div className="account_box">
                <section className="about">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-3 offset-md-1">
                            <div className="avatar">
                                <img src={this.state.preview} alt={this.state.user_nick}/>
                            </div>
                        </div>
                        <div className="u-col-12 u-col-md-7">
                            <span className="user_name">{this.state.user_nick}</span>
                            <h2>Kilka słów o mnie...</h2>
                            <p>
                                {this.state.user_desc}
                            </p>
                        </div>
                    </div>
                </section>
                <section className="skills" style={{backgroundImage:heart}}>
                    <div className="heart"><img src={heart} alt="heart"/></div>
                    <div className="u-row">
                        <div className="u-col-10 u-col-md-5 offset-2 offset-md-1 mx-md-auto">
                            <ul className="skills-items">
                            {(this.state.user_sex === undefined || this.state.user_sex.length === 0)?null:parse(`<li>${this.state.user_sex['label']}</li>`) }
                            {(this.state.user_birth === undefined || this.state.user_birth.length === 0)?null:parse(`<li>${this.calculateAge(this.state.user_birth)}</li>`) }
                            {(this.state.user_city === undefined || this.state.user_city.length === 0)?null:parse(`<li>${this.state.user_city}</li>`) }
                            {(this.state.user_country === undefined || this.state.user_country.length === 0)?null:parse(`<li>${this.state.user_country['label']}</li>`) }
                            </ul>
                        </div>
                        <div className="u-col-10 u-col-md-4 offset-2 offset-md-1 pb-3 align-self-center">
                            {this.renderFiendsButton(this.state.fir_status)}
                        </div>
                    </div>
                </section>
                <section className="gallery">
                    <div className="u-row">
                        {/* {this.drawGallery()} */}
                        {parse(this.state.user_gallery)}
                    </div>
                </section>
                <section className="interest">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-10 mx-md-auto">
                            <h5 className="section-title">Zainteresowania</h5>
                            <ul className="interest-items">
                                {/* {console.log('zainteresowania')} */}
                                {/* {console.log(this.state.user_interest)} */}
                                {(this.state.userMeta.user_interest === undefined)?null:this.state.userMeta.user_interest.map( (interest, index) => <li key={index}>{interest}</li> )}
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="info">
                    <div className="u-row">
                        <div className="u-col-12 u-col-md-10 mx-md-auto">
                            <ul className="info-items">
                                <li><img src={`${baseUri}/zodiak/${this.state.user_zodiak['value']}.png`} alt={this.state.user_zodiak['value']}/></li>
                                <li>
                                    <div className="info-item">
                                        <h5>Znajomość języków obcych</h5>
                                        <p>{(this.state.user_languages === undefined || this.state.user_languages.length === 0)?this.emptyData():this.state.user_languages.map( (language, index ) => <span key={index} className="coma-separate">{language['label']}</span>) }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twoja narodowość</h5>
                                        <p>{(this.state.user_country === undefined || this.state.user_country.length === 0)?this.emptyData():this.state.user_country['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Wyznanie</h5>
                                        <p>{(this.state.user_religion === undefined || this.state.user_religion.length === 0)?this.emptyData():this.state.user_religion }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Szukam...</h5>
                                        <p>{(this.state.user_find === undefined || this.state.user_find.length === 0)?this.emptyData():this.state.user_find }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Interesują Cię...</h5>
                                        <p>{(this.state.user_sex_intrest === undefined || this.state.user_sex_intrest.length === 0)?this.emptyData():this.state.user_sex_intrest['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twój typ sylwetki</h5>
                                        <p>{(this.state.user_body === undefined || this.state.user_body.length === 0)?this.emptyData():this.state.user_body['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Twój stan cywilny</h5>
                                        <p>{(this.state.user_status === undefined || this.state.user_status.length === 0)?this.emptyData():this.state.user_status['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Zawód</h5>
                                        <p>{(this.state.user_work === undefined || this.state.user_work.length === 0)?this.emptyData():this.state.user_work }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Czy masz dzieci?</h5>
                                        <p>{(this.state.user_children === undefined || this.state.user_children.length === 0)?this.emptyData():this.state.user_children['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Wykształcenie</h5>
                                        <p>{(this.state.user_education === undefined || this.state.user_education.length === 0)?this.emptyData():this.state.user_education['label'] }</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="info-item">
                                        <h5>Nałogi</h5>
                                        {/* {console.log('nałogi')} */}
                                        {/* {console.log(this.state.user_addictions)} */}
                                        <p>{(this.state.user_addictions === undefined || this.state.user_addictions.length === 0)?this.emptyData():this.state.user_addictions.map( (addictions, index ) => <span key={index}>{addictions['label']}</span>) }</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}