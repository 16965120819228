import Select from "react-select";

function Step17(props)
{
    if( props.currentStep !== 17 ){
        return null
    }
    const languages = [
        {value:'akan',label:'akan'},
        {value:'amharski',label:'amharski'},
        {value:'angielski',label:'angielski'},
        {value:'arabski',label:'arabski'},
        {value:'asamski',label:'asamski'},
        {value:'awadhi',label:'awadhi'},
        {value:'azerbejdżański',label:'azerbejdżański'},
        {value:'beludżi',label:'beludżi'},
        {value:'bengalski',label:'bengalski'},
        {value:'bhojpuri',label:'bhojpuri'},
        {value:'białoruski',label:'białoruski'},
        {value:'birmańczyk',label:'birmańczyk'},
        {value:'cebuano',label:'cebuano'},
        {value:'chewa',label:'chewa'},
        {value:'chhattisgarhi',label:'chhattisgarhi'},
        {value:'chittagoński',label:'chittagoński'},
        {value:'czeski',label:'czeski'},
        {value:'dekan',label:'dekan'},
        {value:'dhundhari',label:'dhundhari'},
        {value:'Francuski',label:'Francuski'},
        {value:'fula',label:'fula'},
        {value:'gan',label:'gan'},
        {value:'grecki',label:'grecki'},
        {value:'gudżarati',label:'gudżarati'},
        {value:'haryanvi',label:'haryanvi'},
        {value:'Hausa',label:'Hausa'},
        {value:'hinduski',label:'hinduski'},
        {value:'hiszpański',label:'hiszpański'},
        {value:'hmong',label:'hmong'},
        {value:'holenderski',label:'holenderski'},
        {value:'igbo',label:'igbo'},
        {value:'ilocano',label:'ilocano'},
        {value:'japoński',label:'japoński'},
        {value:'jawajski',label:'jawajski'},
        {value:'jin',label:'jin'},
        {value:'joruba',label:'joruba'},
        {value:'kakka',label:'kakka'},
        {value:'Kannada',label:'Kannada'},
        {value:'kazachski',label:'kazachski'},
        {value:'keczua',label:'keczua'},
        {value:'khmerski',label:'khmerski'},
        {value:'kinyarwanda',label:'kinyarwanda'},
        {value:'kirundi',label:'kirundi'},
        {value:'konkani',label:'konkani'},
        {value:'koreański',label:'koreański'},
        {value:'kreolski haitański',label:'kreolski haitański'},
        {value:'kurdyjski',label:'kurdyjski'},
        {value:'maduski',label:'maduski'},
        {value:'magahi',label:'magahi'},
        {value:'maithili',label:'maithili'},
        {value:'malajalam',label:'malajalam'},
        {value:'malajski',label:'malajski'},
        {value:'malgaski',label:'malgaski'},
        {value:'mandaryński',label:'mandaryński'},
        {value:'Marathi',label:'Marathi'},
        {value:'marwari',label:'marwari'},
        {value:'mossi',label:'mossi'},
        {value:'nepalski',label:'nepalski'},
        {value:'niemiecki',label:'niemiecki'},
        {value:'Odia',label:'Odia'},
        {value:'oromo',label:'oromo'},
        {value:'paszto',label:'paszto'},
        {value:'pendżabski',label:'pendżabski'},
        {value:'perski',label:'perski'},
        {value:'polski',label:'polski'},
        {value:'portugalski',label:'portugalski'},
        {value:'rosyjski',label:'rosyjski'},
        {value:'rumuński',label:'rumuński'},
        {value:'saraiki',label:'saraiki'},
        {value:'serbsko-chorwacki',label:'serbsko-chorwacki'},
        {value:'sindhi',label:'sindhi'},
        {value:'somalijski',label:'somalijski'},
        {value:'sundajski',label:'sundajski'},
        {value:'sylheti',label:'sylheti'},
        {value:'syngaleski',label:'syngaleski'},
        {value:'szona',label:'szona'},
        {value:'szwedzki',label:'szwedzki'},
        {value:'tagalski',label:'tagalski'},
        {value:'tajski',label:'tajski'},
        {value:'tamil',label:'tamil'},
        {value:'telugu',label:'telugu'},
        {value:'turecki',label:'turecki'},
        {value:'turkmeński',label:'turkmeński'},
        {value:'ujgurski',label:'ujgurski'},
        {value:'ukraiński',label:'ukraiński'},
        {value:'urdu',label:'urdu'},
        {value:'uzbecki',label:'uzbecki'},
        {value:'węgierski',label:'węgierski'},
        {value:'wietnamski',label:'wietnamski'},
        {value:'włoski',label:'włoski'},
        {value:'wu',label:'wu'},
        {value:'xhosa',label:'xhosa'},
        {value:'xiang',label:'xiang'},
        {value:'zhuang',label:'zhuang'},
        {value:'zulus',label:'zulus'}
    ]
    return(
        <div>
            <h3 className="header">Znajomość języków obcych</h3>
            <p className="lead mb-5"></p>
            <Select 
                isMulti 
                name="user_languages" 
                options={languages} 
                placeholder='wybierz języki'
                className="multi-select"
                onChange={props.handleChange}
                value={props.user_languages}
            />
        </div>
    );
}

export default Step17;