
import {useState} from "react";
import {RMIUploader} from 'react-multiple-image-uploader'

function Step20(props)
{
    const [visible, setVisible] = useState(false);
    if( props.currentStep !== 20 ){
        return null
    }
    const handleSetVisible = () => {
        setVisible(true);
    };
    const hideModal = () => {
        setVisible(false);
    };
    const onUpload = (data) => {
        console.log("Upload files", data);
    };
    const onSelect = (data) => {
        console.log("Select files", data);
    };
    const onRemove = (id) => {
        console.log("Remove image id", id);
    };
    return(
        <div>
            <h3 className="header">Galeria</h3>
            <p className="lead mb-5"></p>
            <RMIUploader
                isOpen={visible}
                onUpload={onUpload}
                //dataSources={''}
            />
        </div>
    );
}

export default Step20;