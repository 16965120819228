import { Component } from "react";
import Avatar from "./Avatar";
import { NavLink } from "react-router-dom";

export default class UserTable extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    render(){
        const data = this.props.data
        return(
            <table className="users_list">
                <thead>
                    <tr>
                        <th colSpan="2">
                            {this.props.label}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map( (data, index) => 
                        {if( data.author === sessionStorage.getItem('userID') ){
                            return(
                                <tr key={index}>
                                    <td className="user">
                                        <a to={`/users/${data.who}`}>
                                            <Avatar user_id={data.who}/>
                                        </a>
                                    </td>
                                    <td className="time"><span className="af-clock me-2"></span>{data.time}</td>
                                </tr>
                            )
                        }else{
                            return(
                                <tr key={index}>
                                    <td className="user">
                                        <a to={`/users/${data.author}`}>
                                            <Avatar user_id={data.author}/>
                                        </a>
                                    </td>
                                    <td className="time"><span className="af-clock me-2"></span>{data.time}</td>
                                </tr>
                            )
                        }}
                    )}
                </tbody>
            </table>
        )
    }
}