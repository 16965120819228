import React, { Component } from "react";
import parse from "html-react-parser";
import axios from "axios";
import Loader from './loader';
import Comments from './Comments';
import Avatar from "./Avatar";
import Editor,{createEditorStateWithText} from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import {stateToHTML} from 'draft-js-export-html';
import config from '../config.json'

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const plugins = [emojiPlugin];
const text = '';

function makeHumanDate(date){
    date = date.split("-")
    var month = ''
    switch( date[1] ){
        case "01":
            month = "STY"
        break;
        case "02":
            month = "LUT"
        break;
        case "03":
            month = "MAR"
        break;
        case "04":
            month = "KWI"
        break;
        case "05":
            month = "MAJ"
        break;
        case "06":
            month = "CZE"
        break;
        case "07":
            month = "LIP"
        break;
        case "08":
            month = "SIE"
        break;
        case "09":
            month = "WRZ"
        break;
        case "10":
            month = "PAŹ"
        break;
        case "11":
            month = "LIS"
        break;
        case "12":
            month = "GRU"
        break;
        default:
            month = "STY"
        break;
    }
    return date[2]+" "+month+" "+date[0]
}


export default class PostView extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            posts: [],
            commentsCount: 0,
            isLoaded: false
        }
    }

    componentDidMount(){
        axios.get(
            `${config.dbURL}/wp-json/fac/v1/post/${this.props.post_id}`
        ).then( res => this.setState({
            posts: res.data,
            isLoaded: true
        })).catch( err => {
            console.warn( err );
        })
        axios
            .get(`${config.dbURL}/wp-json/fac/v1/comments/${this.props.post_id}`)
            .then(res => {
                if( res.data !== "" ){
                    this.setState({
                        commentsCount:res.data.length
                    })
                }else{
                    this.setState({
                        commentsCount:0
                    })
                }
            })
            .catch(
                err => {console.info( err )}
            )

    }

    handleBack = () => {
        localStorage.setItem('currentView','all-posts');
        window.location.reload(true);
    }

    makeHumanDate(date){
        date = date.split("-")
        var month = ''
        switch( date[1] ){
            case "01":
                month = "STY"
            break;
            case "02":
                month = "LUT"
            break;
            case "03":
                month = "MAR"
            break;
            case "04":
                month = "KWI"
            break;
            case "05":
                month = "MAJ"
            break;
            case "06":
                month = "CZE"
            break;
            case "07":
                month = "LIP"
            break;
            case "08":
                month = "SIE"
            break;
            case "09":
                month = "WRZ"
            break;
            case "10":
                month = "PAŹ"
            break;
            case "11":
                month = "LIS"
            break;
            case "12":
                month = "GRU"
            break;
            default:
                month = "STY"
            break;
        }
        return date[2]+" "+month+" "+date[0]
    }
    handleChange = () => {
        console.log( 'dodaj like' );
    }

    deletePost = e => {
        const post_ID = e.target.value;
        axios.post(
            `${config.dbURL}/wp-json/fac/v1/delete/${post_ID}`
        ).then(res=>{
            localStorage.setItem('currentView', 'all-posts')
            window.location.reload(true)
        })
        console.log(post_ID)
    }

    render(){
        const {posts, isLoaded} = this.state;


        if( isLoaded ){
            return(
                <div className="u-row">
                    <div className="u-col-12">
                        <button className="go-back mb-3" onClick={this.handleBack}><span className="af-arrow-right icon-rotate-x"></span>&nbsp;Zamknij</button>
                        <div className="post-card text-card">
                            <header>
                                <div>
                                    { this.makeHumanDate( posts[0].created.split(" ")[0] ) }
                                </div>
                                <div className="text-center">
                                    {( sessionStorage.getItem('userID') === posts[0].author )?<button onClick={this.deletePost} value={posts[0].post_ID} >usuń ten post</button>:null}
                                </div>
                                <div className="text-right">
                                    <span className="af-clock"></span>&nbsp;{ posts[0].created.split(" ")[1] }<br/>
                                </div>
                            </header>
                            <aside>
                                <h2>{posts[0].title}</h2>
                                {(posts[0].content === null)?null:parse(posts[0].content.toString())}
                            </aside>
                            <footer className="align-items-center">
                                <div>
                                    <Avatar user_id={posts[0].author}/>
                                </div>
                                <div className="card-meta text-right">
                                    <button onClick={this.handleChange}><span className="af-heart"></span>&nbsp;{posts[0].likes}</button>
                                    <button><span className="af-comments"></span>&nbsp;{this.state.commentsCount}</button>
                                </div>
                            </footer>
                        </div>
                        <Comments post_id={this.props.post_id}/>
                    </div>
                </div>
            );
        }else{
            return <Loader />
        }
    }
}