export default function Alerts(props){
        if( props.type === 'info' ){
            return(
                <aside className="message message-info">
                    {props.message}
                </aside>
            )
        }
        if( props.type === 'warning' && props.message === undefined ){
            return(
                <aside className="message message-warning">
                    Wystąpił nieoczekiwany błąd aplikacji.
                </aside>
            )
        }
        if( props.type === 'warning' && props.message !== undefined ){
            return(
                <aside className="message message-warning">
                    {props.message}
                </aside>
            )
        }
        if( props.type === 'success' ){
            return(
                <aside className="message message-success">
                    {props.message}
                </aside>
            )
        }
        return(
            null
        )
}